.service_list_section {
  margin-bottom: 100px;

  &_wrapper {
    display: flex;
  }

  .list_titles {
    min-width: 290px;
    margin-right: 40px;

    @media all and (max-width: 1024px) {
      min-width: 240px;
    }

    @media all and (max-width: 767px) {
      display: none;
    }

    .list_title {
      a {
        text-decoration: none;
        color: $black_text_color;
        font-family: $Arial-400;
        font-size: 16px;
        line-height: 36px;
        @media all and (max-width: 1024px) {

        }
      }

      &.active {
        a {
          text-decoration-line: underline;
        }
      }
    }
  }

  .list_items {
    flex: 1;
    height: 580px;
    overflow-y: auto;
    @media all and (max-width: 767px) {
      height: auto;
    }

    .list_item {
      margin-bottom: 54px;
      scroll-margin-top: 150px;
      &.terms_item {
        .list_item_text {
          margin-bottom: 20px;
          &.bg {
            font-family: $Arial-700;
            font-size: 14px;
          }
        }
        .terms_list {
          padding: 0 0 0 20px;
          margin-bottom: 20px;
          list-style: circle;
          li {
            color: $black_text_color;
            font-family: $Arial-400;
            line-height: 20px;
            letter-spacing: 0.6px;
            font-size: 14px;
          }
        }
        h4 {
          color: $black_text_color;
          font-family: $Arial-700;
          letter-spacing: 0.6px;
          font-weight: 700;
          font-size: 16px;
          line-height: 28px;
          margin: 0 0 12px;
        }
      }
      @media all and (max-width: 1024px) {
        margin-bottom: 32px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &_title {
        color: $black_text_color;
        font-family: $Arial-700;
        letter-spacing: 0.6px;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 12px;
        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 14px;
        }
      }

      &_text {
        color: $black_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0;

        @media all and (max-width: 1024px) {
          font-size: 14px;
          line-height: 24px;
        }

        .list_item_email {
          padding-left: 8px;
          color: $blue_color;
        }
      }
    }
  }
}