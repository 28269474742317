.products_wrapper {
  @import "account_types";
  @import "payment_methods";
  @import "withdrawal_methods";

  padding: 70px 0 130px;
  box-sizing: border-box;
  @media all and (max-width: 1024px) {
    padding: 46px 0 80px;

  }

  .title_group {
    display: flex;
    margin-bottom: 84px;
    align-items: flex-start;
    @media all and (max-width: 1024px) {
      margin-bottom: 80px;
    }
    @media all and (max-width: 767px) {
      margin-bottom: 54px;
      flex-direction: column;
    }

    &_title {
      min-width: 320px;
      color: $black_text_color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $Kameron-400;
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      margin: 0 130px 0 0;
      @media all and (max-width: 1024px) {
        font-size: 24px;
        line-height: 28px;
        min-width: 220px;
        margin: 0 30px 0 0;
      }
      @media all and (max-width: 767px) {
        margin: 0 0 30px;
      }
    }

    &_description {
      margin: 0;
      color: $black_text_color;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      @media all and (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .methods_layout {

    .methods_table {
      display: flex;
      position: relative;
      margin-bottom: 52px;
      @media all and (max-width: 1024px) {
        margin-bottom: 30px;
      }
      @media all and (max-width: 767px) {
        overflow-x: scroll;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }

      &:after {
        content: '';
        position: absolute;
        height: 60px;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0.5;
        z-index: -1;
        background: linear-gradient(131deg, #D4FFED 9.04%, #FCFEBB 79.16%);
      }

      .table_column {
        flex: 1;
        border-right: 1px solid rgba(25, 25, 25, 0.24);

        &:last-child {
          border-right: none;
          @media all and (max-width: 1200px) {
            flex: 2;
          }
        }

        &_head {
          box-sizing: border-box;
          padding: 14px 20px;
          color: $black_text_color;
          font-family: $Arial-700;
          font-size: 16px;
          line-height: 36px;
          text-align: end;
          @media all and (max-width: 1200px) {
            font-size: 14px;
            white-space: nowrap;
            padding: 12px 10px;
          }
        }

        &.main {
          .table_column_item {
            font-family: $Arial-700;
            text-align: start;
            @media all and (max-width: 1200px) {
              white-space: nowrap;
            }
          }

          .table_column_head {
            text-align: start;
          }
        }

        &_item {
          box-sizing: border-box;
          color: $black_text_color;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 16px;
          padding: 0 20px;
          line-height: 36px;
          text-align: end;
          @media all and (max-width: 1200px) {
            padding: 0 10px;
            font-size: 14px;
            //white-space: nowrap;
            //overflow: hidden;
            //text-overflow: ellipsis;
          }
        }
      }
    }

    .table_description {
      color: rgba(25, 25, 25, 0.5);
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin: 0;

      &:last-of-type {
        margin-bottom: 100px;
        @media all and (max-width: 1024px) {
          margin-bottom: 60px;
        }
        @media all and (max-width: 576px) {
          margin-bottom: 50px;

        }
      }
    }

    .methods_features {
      display: flex;
      margin: 0 -10px;

      @media all and (max-width: 1024px) {
        flex-wrap: wrap;
      }

      &_item {
        padding: 0 10px;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:nth-child(1), &:nth-child(2) {
          @media all and (max-width: 1024px) {
            margin-bottom: 30px;
          }
        }

        @media all and (max-width: 1024px) {
          width: 50%;
          flex: none;
        }

        &_icon {
          flex: 1;
          border-radius: 12px;
          background: $blue_color_08;
          max-width: 88px;
          width: 88px;
          height: 88px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          @media all and (max-width: 576px) {
            width: 48px;
            height: 48px;
            flex: none;
            margin-right: 10px;
          }

          img {
            display: block;
          }
        }

        &_title {
          flex: 2;
          color: $black_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 21px;
          line-height: 24px;
          margin: 0;
          @media all and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
}