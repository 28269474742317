@import "variables";
@import "header";
@import "./home/index";
@import "products/index";
@import "trade/index";
@import "faqs/index";
@import "service/index";
@import "why_o2t/index";
@import "education_center/index";
@import "tutorials/index";
@import "auth/index";
@import "contact_us/index";
@import "commons/index";
@import "ico-moon-fonts.css";
@import "./accordion_styles/index";
@import "footer";
@import "privacy";
@import "loader";

html {
  scroll-behavior: smooth;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  margin: 0;
  padding: 0;
  background: $bg_color;

  &.is_menu_visible {
    overflow: hidden;
  }
}

.container {
  max-width: 1400px;
  width: 100%;
  padding: 0 60px;
  margin: 0 auto;
  box-sizing: border-box;
  @media all and (max-width: 1024px) {
    padding: 0 24px;
    max-width: 808px;
  }
  @media all and (max-width: 767px) {
    padding: 0 16px;
  }
}