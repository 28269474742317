
.account_types {

  .container {
    &.accounts {
      position: relative;
      @media all and (max-width: 1024px) {
        padding: 0 24px;
        max-width: none;
      }
      @media all and (max-width: 767px) {
        padding: 0 16px;
      }

      .accounts_disclaimer {
        position: absolute;
        left: 0;
        bottom: -60px;
        margin: 0;
        font-size: 18px;
        line-height: 24px;
        color: $black_text_color;
        font-family: $Arial-400;
        @media all and (max-width: 1024px) {
          padding: 0 24px;
          width: 100%;
          box-sizing: border-box;

        }
        @media all and (max-width: 767px) {
          bottom: -50px;
          font-size: 14px;
          line-height: 18px;
          padding: 0 16px;
          text-align: center;
        }
      }
    }

    padding: 0;
    @media all and (max-width: 1024px) {
      padding: 0 24px;
      max-width: 808px;
    }
    @media all and (max-width: 767px) {
      padding: 0 16px;

    }
  }

  &_wrapper {
    max-width: 1400px;
    width: 100%;
    padding: 0 60px;
    margin: 0 auto;
    box-sizing: border-box;
    @media all and (max-width: 1024px) {
      padding: 0;
      max-width: none;
    }
    @media all and (max-width: 767px) {
      padding: 0;
    }
  }

  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
  display: none;

  .container.types {
    @media all and (max-width: 1200px) {
      max-width: none;
    }
  }

  &.visible {
    display: flex;
    opacity: 1;
    position: static;
    transition: opacity 0.5s;
    height: auto;
    z-index: 0;
  }

  &_list {
    display: flex;
    margin: 0 0 120px;

    @media all and (max-width: 767px) {
      margin: 0 0 100px;
      overflow: auto;
      padding: 26px 0 14px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    .account {
      padding: 46px 70px;
      border-radius: 20px;
      border: 1px solid rgba(25, 25, 25, 0.12);
      background: #fff;
      flex: 1;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 1200px) {
        padding: 40px 32px;
      }
      @media all and (max-width: 992px) {
        padding: 40px 20px;
      }


      &_status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 46px;
        @media all and (max-width: 1024px) {
          margin-bottom: 30px;
        }

        &_title {
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 28px;
          margin: 0;
          @media all and (max-width: 1024px) {
            font-size: 20px;
          }
        }

        &_bonus {
          display: block;
          border-radius: 16px;
          background: linear-gradient(143deg, #D4FFED -3.31%, #FCFEBB 78.05%);
          color: $black_color;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          padding: 6px 18px;
          box-sizing: border-box;
          @media all and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }

      &_range_title {
        display: block;
        color: $black_text_color;
        opacity: 0.5;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      &_range_amount {
        display: block;
        color: $black_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 36px;
        line-height: 36px;
        padding-bottom: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(25, 25, 25, 0.24);
        margin-bottom: 30px;
        @media all and (max-width: 1024px) {
          font-size: 24px;
          padding-bottom: 14px;
          margin-bottom: 14px;
        }
      }

      &_features {
        padding: 0 0 0 35px;
        box-sizing: border-box;
        margin-bottom: 60px;
        @media all and (max-width: 1024px) {
          margin-bottom: 40px;
        }

        &_item {
          position: relative;
          color: $black_text_color;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          @media all and (max-width: 1024px) {
            font-size: 14px;
            line-height: 20px;
          }

          svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -35px;
          }
        }
      }

      &.Titanium {
        padding: 59px 70px 61px;
        transform: scale(1.05);
        position: relative;
        border: 1px solid $blue_color;
        box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
        @media all and (max-width: 1200px) {
          padding: 48px 32px 48px;
        }
        @media all and (max-width: 992px) {
          padding: 48px 20px 48px;
        }

        .account_status {
          margin-bottom: 39px;
          @media all and (max-width: 1024px) {
            margin-bottom: 26px;
          }
        }

        .account_features {
          &_item {
            &:nth-child(5) {
              margin-bottom: 40px;
              @media all and (max-width: 1024px) {
                margin-bottom: 20px;
              }
            }
          }
        }

        &:before {
          position: absolute;
          content: attr(data-header);
          top: -12px;
          left: -1px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          border-radius: 20px 20px 0px 0px;
          background: #36F;
          border: 1px solid $blue_color;
          color: #FFF;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }

      &.Platinum {
        .account_features {
          &_item {
            &:nth-child(5) {
              margin-bottom: 40px;
              @media all and (max-width: 1024px) {
                margin-bottom: 20px;
              }
            }
          }
        }
      }

      &_cta {
        display: flex;
        text-decoration: none;
        margin: auto 0 0;

        button {
          width: 100%;
        }
      }
    }
  }

  .black_account {
    display: flex;
    align-items: stretch;
    border-radius: 20px;
    overflow: hidden;
    @media all and (max-width: 576px) {
      flex-direction: column;
    }

    &_media {
      width: 50%;
      @media all and (max-width: 576px) {
        width: 100%;
        order: 1;
        height: 228px;
      }

      &_img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    &_info {
      width: 50%;
      background: $black_text_color;
      padding: 78px 92px 32px;
      box-sizing: border-box;
      @media all and (max-width: 1024px) {
        padding: 30px;
      }
      @media all and (max-width: 576px) {
        width: 100%;
        order: 2;
      }

      &_title {
        color: #FFF;
        font-family: $Kameron-400;
        font-weight: 400;
        font-size: 56px;
        line-height: 56px;
        margin: 0 0 90px;
        @media all and (max-width: 1024px) {
          font-size: 36px;
          line-height: 36px;
          margin: 0 0 50px;
        }
      }

      &_cta {
        text-decoration: none;
        margin: 0 0 32px;
        display: block;
        @media all and (max-width: 1024px) {
          margin: 0 0 12px;
        }
      }

      &_description {
        color: $white_text_color_50;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        @media all and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}