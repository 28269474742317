body.webp {
  .categories_section {
    .guides {
      .guides_beginners {
        &_wrapper {
          background-image: url("/img/pages/education_center/beginn_bg.webp");
        }
      }
    }
  }
}

.categories_section {

  position: relative;

  .border {
    padding: 0 0 130px;
    margin: -300px 0 70px;
    border-bottom: 1px solid $black_text_color_024;
    @media all and (max-width: 1024px) {
      padding: 0 0 64px;
      margin: -300px 0 50px;
    }
  }

  .tutorials {
    margin-bottom: 27px;

    .what_is_description_body_cta {
      text-decoration: none;
    }
  }

  .guides {
    display: flex;
    margin: 0 -18px;
    @media all and (max-width: 767px) {
      flex-direction: column;
      margin: 0;
    }


    .guide {
      padding: 18px;
      @media all and (max-width: 767px) {
        padding: 0;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      .cta {
        text-decoration: none;
      }

      .wrapper {
        border-radius: 20px;
        padding: 46px 40px;
        box-sizing: border-box;
        height: 100%;
        @media all and (max-width: 1200px) {
          padding: 30px;
        }

        h2 {
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Kameron-400;
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: 36px;
          margin: 0 0 36px;
          @media all and (max-width: 1200px) {
            font-size: 32px;
            line-height: 36px;
          }
          @media all and (max-width: 1024px) {
            font-size: 24px;
            margin: 0 0 24px;
          }
        }

        p {
          color: #191919;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          margin: 0 0 28px;
          @media all and (max-width: 1200px) {
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 20px;
          }
        }
      }
    }

    .guides_beginners {
      width: 50%;
      @media all and (max-width: 767px) {
        width: 100%;
      }

      &_wrapper {
        &.wrapper {
          padding: 46px 40px 0;
          @media all and (max-width: 1200px) {
            padding: 30px 30px 0;
          }
        }

        display: flex;
        align-items: flex-end;
        background-color: #fff;
        box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("/img/pages/education_center/beginn_bg.png");
        @media all and (max-width: 1024px) {
          flex-direction: column;
        }
      }

      &_description {
        width: 50%;
        max-width: 280px;
        margin-right: 60px;
        padding-bottom: 46px;
        box-sizing: border-box;
        align-self: flex-start;
        @media all and (max-width: 1024px) {
          width: 100%;
          margin: 0 0 36px;
          max-width: initial;
          padding: 0;
        }

        h2 {
          white-space: nowrap;
        }

        p {
          max-width: 214px;
          margin-bottom: 60px;
          @media all and (max-width: 1024px) {
            margin-bottom: 20px;
            max-width: initial;
          }
        }
      }


      &_media {
        width: 50%;
        @media all and (max-width: 1024px) {
          width: 100%;
        }

        img {
          display: block;
          max-width: 267px;
          width: 110%;
          height: auto;
          @media all and (max-width: 1024px) {
            max-width: 182px;
            margin: 0 auto;
          }
        }
      }
    }

    .guides_advanced_block {
      width: calc(50% + 40px);
      @media all and (max-width: 767px) {
        width: 100%;
      }

      .guides_advanced {
        &_wrapper {
          background: $blue_color_08;
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);

        }
      }

      .help_center {
        &_wrapper {
          background: $black_text_color;
          box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
          display: flex;
          align-items: center;
          @media all and (max-width: 1024px) {
            flex-direction: column;
            align-items: flex-start;
          }

          p {
            max-width: 260px;
            color: $white-text_color;
            margin: 0 38px 0 0;
            @media all and (max-width: 1024px) {
              margin: 0 0 20px;
            }
          }

          button {
            background: rgba(255, 255, 255, 0.24);

            &:hover {
              background: $white_text_color;
              color: $black_text_color;

              svg {
                path {
                  stroke: $black_text_color;
                }
              ;
              }
            }
          }
        }
      }
    }
  }
}