.trusted_section {
  padding: 110px 0 100px;
  position: relative;
  margin-bottom: 70px;
  @media all and (max-width: 1024px) {
    padding: 90px 0 70px;
    margin-bottom: 50px;
  }
  @media all and (max-width: 767px) {
    padding: 60px 0 70px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(51, 102, 255, 0.00) 0%, #36F 100%);
    opacity: 0.08;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_title {
    color: $black_text_color;
    text-align: center;
    font-family: $Kameron-400;
    font-weight: 400;
    font-size: 48px;
    max-width: 510px;
    line-height: 48px;
    margin: 0 0 20px;
    @media all and (max-width: 1024px) {
      font-size: 24px;
      line-height: 28px;
      margin: 0 0 28px;
    }
  }

  &_description {
    color: $black_text_color;
    text-align: center;
    font-family: $Arial-400;
    letter-spacing: 0.6px;
    font-weight: 400;
    font-size: 20px;
    max-width: 510px;
    line-height: 32px;
    margin: 0 0 80px;
    @media all and (max-width: 1024px) {
      font-size: 16px;
      line-height: 20px;
      max-width: 452px;
      margin: 0 0 40px;
    }
  }

  &_main {
    display: flex;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    align-items: stretch;
    width: 100%;
    @media all and (max-width: 576px) {
      flex-direction: column;
    }

    .main_media {
      position: relative;
      width: 50%;
      @media all and (max-width: 576px) {
        width: 100%;
      }

      &_chat {
        position: absolute;
        bottom: 0;
        left: 0;
        height: auto;
        width: 68%;
        z-index: 1;
        @media all and (max-width: 1024px) {
          width: 100%;
        }
        @media all and (max-width: 576px) {
          width: 60%;
        }
      }

      &_img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        @media all and (max-width: 576px) {
          position: static;
        }
      }
    }

    .main_description {
      width: 50%;
      background: white;
      z-index: 1;
      padding: 80px 70px 80px 80px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media all and (max-width: 1024px) {
        padding: 50px;
      }
      @media all and (max-width: 767px) {
        padding: 30px;
      }
      @media all and (max-width: 576px) {
        width: 100%;
      }

      &_item {
        position: relative;
        margin-bottom: 30px;
        padding-left: 50px;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          position: absolute;
          content: '';
          width: 24px;
          height: 24px;
          left: 2px;
          top: 8px;
          z-index: 2;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAyMEMxNS41MjI4IDIwIDIwIDE1LjUyMjggMjAgMTBDMjAgNC40NzcxNSAxNS41MjI4IDAgMTAgMEM0LjQ3NzE1IDAgMCA0LjQ3NzE1IDAgMTBDMCAxNS41MjI4IDQuNDc3MTUgMjAgMTAgMjBaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfNTAxXzIyNjkpIi8+CjxyZWN0IHg9IjkuMDgxNzkiIHk9IjE0LjY1MyIgd2lkdGg9IjEuNTM4NDYiIGhlaWdodD0iMTAuNjgyNSIgcng9IjAuNzY5MjMxIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTQwLjY1OSA5LjA4MTc5IDE0LjY1MykiIGZpbGw9IiMxOTE5MTkiLz4KPHJlY3QgeD0iMTAuMDU1MSIgeT0iMTMuNTgyMyIgd2lkdGg9IjEuNTM4NDYiIGhlaWdodD0iNi4xNTM4NSIgcng9IjAuNzY5MjMxIiB0cmFuc2Zvcm09InJvdGF0ZSgxMzUgMTAuMDU1MSAxMy41ODIzKSIgZmlsbD0iIzE5MTkxOSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzUwMV8yMjY5IiB4MT0iLTAuOTYiIHkxPSItMC40NCIgeDI9IjEyLjY4IiB5Mj0iMTcuOCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRDRGRkVEIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZDRkVCQiIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=");
        }

        &:after {
          position: absolute;
          content: '';
          width: 1px;
          height: calc(100% - 8px);
          top: 8px;
          left: 12px;
          background: $black_text_color_024;
          z-index: 1;
        }

        &_title {
          margin: 0 0 6px;
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-700;
          font-size: 21px;
          line-height: 36px;
          @media all and (max-width: 1024px) {
            font-size: 16px;
            line-height: 28px;
          }
        }

        &_description {
          color: $black_text_color;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
          max-width: 390px;
          @media all and (max-width: 1024px) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}