.withdrawal_methods {
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: -1;
  display: none;

  &.visible {
    display: flex;
    z-index: 0;
    position: static;
    opacity: 1;
    height: auto;
  }
}