.banner_bg {
  position: relative;
  margin-bottom: 90px;
  padding-bottom: 80px;
  @media all and (max-width: 1024px) {
    margin-bottom: 70px;
    padding-bottom: 70px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(51, 102, 255, 0.00) 0%, #36F 100%);
    opacity: 0.08;
    top: 0;
    z-index: -1;
    left: 0;
  }

  .banner_section {
    padding: 137px 0 0;
    z-index: 2;
    position: relative;
    @media all and (max-width: 1024px) {
      padding: 94px 0 0;
    }

    &_wrapper {
      display: flex;
      align-items: center;
    }

    &_title {
      color: $black_text_color;
      text-align: center;
      font-family: $Kameron-400;
      font-weight: 400;
      font-size: 64px;
      line-height: 64px;
      margin: 0 0 130px;
      @media all and (max-width: 1024px) {
        font-size: 36px;
        line-height: 36px;
        margin: 0 0 50px;
      }
    }
  }
}

