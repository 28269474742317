.join_section {
  padding: 96px 0 122px;
  @media all and (max-width: 1024px) {
    padding: 70px 0;
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    @media all and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &_media {
    width: 50%;
    max-width: 550px;
    position: relative;
    padding-left: 40px;
    box-sizing: border-box;
    @media all and (max-width: 1024px) {
      padding-right: 20px;
    }
    @media all and (max-width: 767px) {
      width: 100%;
      max-width: none;
      padding: 0 60px 0 0;
    }

    .girl_working {
      width: 100%;
      height: auto;
      display: block;
      object-fit: cover;
    }

    .chat {
      position: absolute;
      max-width: 377px;
      width: 69%;
      height: auto;
      display: block;
      bottom: 30px;
      right: -58px;
      @media all and (max-width: 1024px) {
        width: 79%;
        right: -5%;
        bottom: 10px
      }
      @media all and (max-width: 767px) {
        right: 0;
      }
    }
  }

  &_description {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media all and (max-width: 767px) {
      width: 100%;
      margin-bottom: 40px;
    }

    &_title {
      color: $black_color;
      font-family: $Kameron-400;
      font-weight: 400;
      font-size: 48px;
      line-height: 48px;
      margin: 0 0 20px;
      max-width: 400px;
      @media all and (max-width: 1024px) {
        font-size: 24px;
        line-height: 28px;
        max-width: 230px;
      }
      @media all and (max-width: 767px) {
        max-width: none;
      }
    }

    &_text {
      max-width: 510px;
      margin: 0 0 28px;
      color: $black_text_color;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      @media all and (max-width: 1024px) {
        font-size: 16px;
        line-height: 20px;
        max-width: 278px;
      }
      @media all and (max-width: 767px) {
        max-width: none;
      }
    }
  }

  &_cta {
    display: flex;
    margin: 0 -11px;


    .cta {
      cursor: pointer;
      padding: 0 11px;
      box-sizing: border-box;
      text-decoration: none;

      .store_button {
        padding: 10px 16px;
        border-radius: 12px;
      }
    }
  }
}