@import "variables";

.footer {
  padding: 0 0 70px;
  @media all and (max-width: 1024px) {
    padding: 0 0 30px;
  }

  &_disclaimer {
    margin: 0 0 56px;
    color: $black_text_color_50;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $Arial-400;
    letter-spacing: 0.5px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.5px;
    @media all and (max-width: 767px) {
      margin: 0 0 40px;
    }
  }

  &_top {
    display: flex;
    justify-content: space-between;
    padding: 0 0 50px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.24);
    margin-bottom: 50px;

    @media all and (max-width: 1024px) {
      padding: 0 0 30px;
      margin-bottom: 30px;
    }
    @media all and (max-width: 767px) {
      flex-direction: column;
    }

    .nav_logo {
      margin-right: 40px;
      @media all and (max-width: 767px) {
        svg {
          max-width: 130px;
          margin: 0 0 30px;
        }
      }
    }

    &_download {
      display: flex;
      margin: 0 -10px;

      .cta {
        text-decoration: none;
        padding: 0 10px;
        box-sizing: border-box;
        @media all and (max-width: 576px) {
          width: 100%;
        }

        .store_button {
          padding: 8px 16px;
          border-radius: 12px;
        }
      }
    }
  }

  &_main {
    display: flex;
    margin: 0 -16px 50px;
    @media all and (max-width: 1024px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 767px) {
      margin: 0 -16px 30px;
    }

    &_item {
      padding: 0 16px;
      box-sizing: border-box;
      flex: 1;
      @media all and (max-width: 767px) {
        width: 50%;
        flex: none;
        margin-bottom: 30px;
        &:nth-child(3) {
          margin-bottom: 0;
        }
        &:nth-child(4) {
          margin-bottom: 0;
        }
      }

      &_title {
        color: $black_text_color_50;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 16px;
        @media all and (max-width: 1024px) {
          font-size: 11px;
          margin-bottom: 12px;
        }
      }

      &_list {
        .list_item {
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 14px;
          line-height: 28px;
          text-decoration: none;
          @media all and (max-width: 1024px) {
            font-size: 12px;
            line-height: 24px;

          }
        }
      }
    }
  }

  &_bottom {
    display: flex;
    justify-content: space-between;
    margin: 0 -20px;
    @media all and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
    }

    &_copyright {
      color: $black_text_color_50;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 11px;
      line-height: normal;
      padding: 0 20px;
      margin: 0;
      box-sizing: border-box;
      @media all and (max-width: 1024px) {
        font-size: 10px;
        max-width: 140px;
      }
      @media all and (max-width: 767px) {
        max-width: none;
        margin: 0 0 30px;
      }
    }

    &_nav {
      padding: 0 20px;
      box-sizing: border-box;

      &_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -10px;
        @media all and (max-width: 1024px) {
          margin: 0 -5px;
        }
        @media all and (max-width: 767px) {
          margin: 0 -10px 30px;
          flex-wrap: wrap;
          justify-content: center;
          max-width: 300px;
        }

        .list_item {
          display: flex;
          padding: 0 10px;
          margin-bottom: 6px;
          @media all and (max-width: 1024px) {
            padding: 0 5px;
          }
          @media all and (max-width: 767px) {

          }

          &_link {
            color: $black_text_color_50;
            font-family: $Arial-400;
            letter-spacing: 0.6px;
            font-weight: 400;
            font-size: 11px;
            line-height: normal;
            text-decoration-line: underline;
            @media all and (max-width: 1024px) {
              font-size: 10px;
            }
          }
        }
      }
    }

    &_social {
      display: flex;
      padding: 0 20px;

      &_item {
        margin-right: 40px;
        @media all and (max-width: 1024px) {
          margin-right: 14px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}