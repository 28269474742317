.tutorials_section {
  padding: 34px 0 170px;
  box-sizing: border-box;
  @media all and (max-width: 1024px) {
    padding: 34px 0 70px;

  }

  &.post {
    padding: 90px 0 130px;
    @media all and (max-width: 1024px) {
      padding: 50px 0 90px;
    }
  }

  &.guides {
    .tutorials_section_wrapper {
      .tutorials_items {
        &.advanced.introduction {
          margin: -540px 0 0 440px;
          @media all and (max-width: 1200px) {
            margin: -467px 0 0 440px;
          }
          @media all and (max-width: 1024px) {
            margin: -417px 0 0 290px;
          }
        }

        &.advanced.terminologies {
          margin: -474px 0 0 440px;
          @media all and (max-width: 1200px) {
            margin: -467px 0 0 440px;
          }
          @media all and (max-width: 1024px) {
            margin: -417px 0 0 290px;
          }
        }

        &.beginners.terminologies {
          margin: -716px 0 0 440px;
          @media all and (max-width: 1200px) {
            margin: -687px 0 0 440px;
          }
          @media all and (max-width: 1024px) {
            margin: -618px 0 0 290px;
          }
        }

        &.beginners.introduction {
          margin: -618px 0 0 440px;
          @media all and (max-width: 1024px) {
            margin: -618px 0 0 290px;
          }
        }

        &.post {

        }

        &.post.beginners.introduction {
          @media all and (max-width: 1024px) {
            margin: -566px 0 0 290px;
          }
          @media all and (max-width: 767px) {
            margin: 0 0 40px;
            order: 1;
          }
        }

        &.post.beginners.terminologies {
          @media all and (max-width: 767px) {
            margin: 0 0 40px;
            order: 1;
          }
        }

        &.post.advanced.introduction {
          @media all and (max-width: 767px) {
            margin: 0 0 40px;
            order: 1;
          }
        }

        &.post.advanced.terminologies {
          @media all and (max-width: 767px) {
            margin: 0 0 40px;
            order: 1;
          }
        }

        margin: -172px 0 0 440px;
        @media all and (max-width: 1200px) {
          margin: -172px 0 0 350px;
        }
        @media all and (max-width: 1024px) {
          margin: -160px 0 0 250px;
        }
        @media all and (max-width: 767px) {
          margin: 0;
        }
      }
    }
  }

  &_wrapper {
    display: block;
    @media all and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }

    .post {
      display: flex;
      flex-direction: column;

      &_title {
        color: $black_text_color;
        font-family: $Kameron-400;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 64px;
        margin: 0 0 28px;
        @media all and (max-width: 1200px) {
          font-size: 40px;
          line-height: 52px;
        }
        @media all and (max-width: 1024px) {
          font-size: 36px;
          line-height: 36px;
          margin: 0 0 20px;
        }
      }

      &_category {
        display: block;
        align-self: flex-start;
        color: $black_text_color;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 6px 20px;
        margin: 0 0 60px;
        border-radius: 16px;
        background: $black_text_color_08;
        text-transform: capitalize;
        @media all and (max-width: 1024px) {
          margin: 0 0 30px;
          font-size: 14px;
        }
      }

      &_image {
        max-height: 430px;
        overflow: hidden;
        margin-bottom: 60px;
        border-radius: 36px;
        @media all and (max-width: 1024px) {
          margin-bottom: 30px;
          max-height: 236px;
        }
        @media all and (max-width: 767px) {
          max-height: initial;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      &_body {
        p, li {
          color: $black_text_color;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0 0 20px;
          @media all and (max-width: 1024px) {
            font-size: 14px;
            line-height: 20px;
          }

        }

        .big_font {
          font-size: 20px;
          line-height: 28px;
          @media all and (max-width: 1024px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        h3, h4 {
          color: $black_text_color;
          font-family: $Arial-700;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 72px;
          margin: 0;
        }

        b {
          font-family: $Arial-700;
        }

        h4 {
          font-size: 20px;
          line-height: 24px;
          margin: 0 0 10px;
        }

        ul {
          display: flex;
          list-style: decimal;
          flex-direction: column;
          padding-left: 30px;
        }
      }

      &_no_data {
        padding: 300px 0;
        text-align: center;
        color: $black_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .list_category {
      width: 290px;
      max-width: 290px;
      margin-right: 0;
      position: sticky;
      top: 100px;
      display: flex;
      flex-direction: column;

      &.post {
        @media all and (max-width: 1200px) {
          width: auto;
          max-width: 240px;
        }
        @media all and (max-width: 767px) {
          order: 2;
          max-width: none;
          .category_item {
            width: initial;
          }
        }
      }

      @media all and (max-width: 1200px) {
        width: auto;
        max-width: 220px;
      }

      @media all and (max-width: 1200px) {
        max-width: 200px;
      }
      @media all and (max-width: 767px) {
        position: static;
      }

      .post_head {
        display: flex;
        align-items: center;
        padding-bottom: 26px;
        border-bottom: 4px solid;
        margin-bottom: 26px;
        @media all and (max-width: 1024px) {
          margin-bottom: 20px;
          padding-bottom: 20px;
        }

        &_img {
          display: block;
          margin-right: 22px;
          @media all and (max-width: 1024px) {
            width: 48px;
          }
        }

        &_info {
          display: flex;
          flex-direction: column;

          h4, .underline {
            color: $black_text_color;
            font-family: $Arial-700;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            margin: 0;
            @media all and (max-width: 1024px) {
              font-size: 16px;
            }
          }

          .underline {
            text-decoration: underline;
          }

          span {
            color: $black_text_color_50;
            font-family: $Arial-400;
            letter-spacing: 0.6px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            @media all and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }
      }

      .post_share {
        display: flex;
        align-items: center;
        margin-bottom: 44px;

        h5 {
          color: $black_text_color;
          font-family: $Arial-400;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 48px;
          margin: 0 30px 0 0;
        }

        &_icons {
          display: flex;

          a {
            display: block;
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }
          }

          img {
            display: block;
            width: 20px;
            height: 20px;


          }
        }
      }

      &_title {
        color: $black_text_color;
        font-family: $Arial-700;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 72px;
        position: relative;
        margin: 0 0 30px;
        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 56px;
        }

        &:after {
          position: absolute;
          content: '';
          bottom: 0;
          width: 100%;
          height: 4px;
          left: 0;
          background: $black_text_color;
        }
      }

      .category_item {
        display: inline-block;
        box-sizing: border-box;
        color: $black_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-decoration: none;
        margin-bottom: 24px;
        border-bottom: 1px solid transparent;
        @media all and (max-width: 1200px) {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 20px;
        }

        .category_item {

        }

        &.active {
          border-color: $black_text_color;
        }
      }
    }

    .tutorials_items {
      margin: -220px 0 0 440px;

      .no_data {
        padding: 40px 0;
        display: flex;
        box-sizing: border-box;
        color: $black_text_color;
        text-align: center;
        font-family: $Arial-400;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .platform_types {
        display: flex;
        margin-bottom: 60px;

        .type_item {
          padding: 6px 12px;
          color: $black_text_color;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 16px;
          background: rgba(25, 25, 25, 0.08);
          margin-right: 16px;
          display: flex;
          text-decoration: none;

          &.active {
            color: $white_text_color;
            background: $blue_color;
          }
        }
      }

      .list_items {
        display: flex;
        margin: 0 -20px;
        flex-wrap: wrap;
        @media all and (max-width: 1024px) {
          margin: 0 -15px;
        }
        @media all and (max-width: 576px) {
          flex-direction: column;
        }

        .guides_list_item {

          .item_wrapper {
            text-decoration: none;
            display: block;
            border-radius: 20px;
            border: 1px solid rgba(25, 25, 25, 0.12);
            height: 100%;
          }

          box-sizing: border-box;
          padding: 0 20px 40px;
          width: 50%;
          border-radius: 20px;
          overflow: hidden;
          background: #FFF;
          @media all and (max-width: 1024px) {
            padding: 0 15px 30px;
          }
          @media all and (max-width: 576px) {
            width: 100%;
          }


          &_img {
            height: 210px;
            overflow: hidden;
            @media all and (max-width: 1024px) {
              height: 148px;
            }
            @media all and (max-width: 576px) {
              height: auto;
            }

            img {
              width: 100%;
              display: block;
              height: auto;
            }
          }

          &_body {
            padding: 36px 40px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media all and (max-width: 1024px) {
              padding: 20px;
            }

            h3 {
              color: $black_text_color;
              font-family: $Arial-700;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
              margin: 0 0 30px;
              width: 100%;
              @media all and (max-width: 1024px) {
                font-size: 16px;
                line-height: 24px;
                margin: 0 0 16px;
              }
            }

            span {
              //width: 50%;
              color: $black_text_color_50;
              font-family: $Arial-400;
              letter-spacing: 0.6px;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              @media all and (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }
        }

        .list_item {
          box-sizing: border-box;
          padding: 0 20px 50px;
          width: 50%;


          &_video {
            height: 210px;
            background-image: url("/img/pages/tutorials/default.png");
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            margin-bottom: 16px;

            .play_button {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              cursor: pointer;

              path {
                transition: all 0.3s ease-in-out;
              }


              &:hover {
                path {
                  fill: $blue_color;
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }

          &_title {
            color: $black_text_color;
            font-family: $Arial-700;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 72px;
            margin: 0;
            padding: 0 0 12px;
            border-bottom: 1px solid;
          }
        }
      }
    }
  }
}