.featured_in_section {
  padding: 74px 0;
  @media all and (max-width: 1024px) {
    padding: 60px 0;
  }

  &_wrapper {
    display: flex;
    align-items: center;
  }

  &_title {
     font-family: $Arial-400;
        letter-spacing: 0.6px;font-weight: 400;
    font-size: 20px;
    line-height: normal;
    color: $black_text_color;
    margin: 0 200px 0 0;
    @media all and (max-width: 1024px) {
      display: none;
    }
  }

  &_list {
    display: flex;
    justify-content: space-between;
    margin: 0 -16px;
    flex: 1;

    @media all and (max-width: 767px) {
      flex-wrap: wrap;
    }

    .list_item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      box-sizing: border-box;
      @media all and (max-width: 767px) {
        width: 50%;
      }

      &:first-child {
        justify-content: flex-start;
        @media all and (max-width: 767px) {
          justify-content: center;
          margin-bottom: 50px;
        }
      }

      &:nth-child(2) {
        @media all and (max-width: 767px) {
          margin-bottom: 50px;
        }
      }

      &:last-child {
        justify-content: flex-end;
        @media all and (max-width: 767px) {
          justify-content: center;
        }
      }

      img {
        width: 100%;
        display: block;
        height: auto;
        object-fit: contain;
        object-position: center;
        @media all and (max-width: 767px) {
          max-width: 200px;
          width: 77%;
        }
      }
    }
  }
}