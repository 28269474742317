.banner_bg {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(51, 102, 255, 0.00) 0%, #36F 100%);
    opacity: 0.08;
    top: 0;
    left: 0;
    transform: rotateX(180deg);
  }

  .banner_section {
    padding: 155px 0 134px;
    margin-bottom: 40px;
    z-index: 2;
    position: relative;

    @media all and (max-width: 1024px) {
      padding: 105px 0 70px;
      margin-bottom: 0;
    }

    &_wrapper {
      display: flex;
      align-items: center;
      @media all and (max-width: 576px) {
        flex-direction: column;
      }
    }

    &_title {
      width: 50%;
      padding-right: 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 576px) {
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
      }

      &_title {
        color: $black_color;
        font-family: $Kameron-400;
        font-weight: 400;
        font-size: 64px;
        line-height: 64px;
        margin: 0 0 50px;
        @media all and (max-width: 1024px) {
          font-size: 36px;
          line-height: 36px;
          margin: 0 0 40px;
        }
        @media all and (max-width: 576px) {
          text-align: center;
        }
      }

      &_description {
        color: $black_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 40px;
        max-width: 510px;
        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 20px;
          margin: 0 0 30px;
        }
        @media all and (max-width: 576px) {
          text-align: center;
          max-width: none;
        }
      }

      &_cta {
        text-decoration: none;
        @media all and (max-width: 576px) {
          margin: 0 auto;
        }
      }
    }

    &.forex {
      .banner_section_media {
        .banner_chat {
          width: 78%;
          height: auto;
          @media all and (max-width: 1024px) {
            width: 70%;
          }
        }
      }
    }

    &.crypto {
      .banner_section_media {
        .banner_chat {
          position: absolute;
          bottom: -19%;
          left: -10px;
          max-width: 543px;
          width: 84%;
          height: auto;
        }
      }
    }

    &.indices {
      .banner_section_media {
        padding-left: 80px;
        box-sizing: border-box;
        @media all and (max-width: 1024px) {
          padding-left: 54px;
        }
        @media all and (max-width: 576px) {
          padding-left: 40px;
        }

        .banner_img {
          max-width: 536px;
          display: block;
          margin-left: auto;
        }

        .banner_chat {
          position: absolute;
          bottom: -20%;
          left: 0%;
          height: auto;
          width: 82%;
          @media all and (max-width: 576px) {
            left: -5%;
          }
        }
      }
    }

    &.commodities {
      .banner_section_media {
        .banner_img {
          max-width: 590px;
          display: block;
          margin-left: auto;
        }

        .banner_chat {
          position: absolute;
          bottom: -10px;
          display: block;
          height: auto;
          width: 100%;
          max-width: 500px;
          left: -60px;
          @media all and (max-width: 576px) {
            left: -11%;
          }
        }
      }
    }

    &.stocks {
      .banner_section_media {
        padding-left: 50px;
        box-sizing: border-box;
        @media all and (max-width: 576px) {
          padding-left: 30px;
        }

        .banner_img {
          max-width: 585px;
          display: block;
          margin-left: auto;
        }

        .banner_chat {
          position: absolute;
          display: block;
          height: auto;
          width: 60%;
          max-width: 388px;
          bottom: -16%;
          left: -3%;
          @media all and (max-width: 576px) {
            left: -5%;
          }
        }
      }
    }

    &_media {
      width: 50%;
      position: relative;
      border-radius: 32px;
      @media all and (max-width: 576px) {
        width: 100%;
      }

      .banner_img {
        width: 100%;
        object-fit: cover;
        border-radius: 32px;
        height: auto;
        object-position: center;
      }

      .banner_chat {
        position: absolute;
        bottom: 20px;
        left: 20px;
      }
    }
  }
}

