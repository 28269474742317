.how_to_section {
  margin-bottom: 100px;
  @media all and (max-width: 1024px) {
    margin-bottom: 70px;
  }

  &_main {
    display: flex;
    margin-bottom: 105px;
    @media all and (max-width: 1024px) {
      margin-bottom: 50px;
    }
    @media all and (max-width: 576px) {
      flex-wrap: wrap;
    }

    .how_to_description {
      width: 50%;
      margin-right: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media all and (max-width: 1024px) {
        margin-right: 0;
        padding-right: 24px;
        box-sizing: border-box;
      }
      @media all and (max-width: 576px) {
        width: 100%;
        margin-bottom: 50px;
      }

      &_title {
        color: $black_text_color;
        font-family: $Kameron-400;
        font-weight: 400;
        font-size: 48px;
        line-height: 48px;
        margin: 0 0 50px;
        max-width: 510px;
        @media all and (max-width: 1024px) {
          margin: 0 0 24px;
          font-size: 24px;
          line-height: 28px;
        }
      }

      &_description {
        color: $black_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        margin: 0 0 50px;
        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 24px;
        }
      }

      &_cta {
        text-decoration: none;
      }
    }

    .how_to_media {
      border-radius: 32px;
      width: 100%;
      box-sizing: border-box;
      background: $blue_color_012;
      max-width: 620px;
      @media all and (max-width: 1024px) {
        width: 50%;
      }
      @media all and (max-width: 576px) {
        width: 100%;
      }

      &.crypto, &.indices, &.commodities {
        background: none;
      }

      &_img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &_additional {
    display: flex;
    margin: 0 -20px;
    @media all and (max-width: 1024px) {
      margin: 0 -16px;
    }
    @media all and (max-width: 767px) {
      flex-wrap: wrap;
    }

    &.stocks {
      .how_to_section_additional_item {
        @media all and (max-width: 767px) {
          width: 100%;
          flex: none;
        }
        &:first-child {
          color: $black_text_color;
          font-size: 20px;
          line-height: 28px;
          @media all and (max-width: 767px) {
           margin-bottom: 32px;
          }
        }
      }
    }

    .stocks_how_to {
      padding-left: 78px;
      box-sizing: border-box;
      position: relative;

      img {
        position: absolute;
        left: 0;
        top: 0;
      }

      h3 {
        margin: 0 0 15px;
        color: #191919;
        font-family: $Arial-700;
        font-size: 20px;
        line-height: 32px;
      }
    }

    &_item {
      padding: 0 20px;
      box-sizing: border-box;
      flex: 1;
      color: $black_text_color_70;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 16px;
      margin: 0;
      line-height: 24px;
      @media all and (max-width: 1024px) {
        font-size: 14px;
        line-height: 20px;
        padding: 0 16px;
      }

      &:last-child {
        @media all and (max-width: 767px) {
          width: 100%;
          flex: none;
          margin-top: 32px;
        }
      }
    }
  }
}