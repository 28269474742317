.banner_bg {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(51, 102, 255, 0.00) 0%, #36F 100%);
    opacity: 0.08;
    top: 0;
    left: 0;
  }

  .banner_section {
    padding: 180px 0 75px;
    z-index: 2;
    position: relative;
    @media all and (max-width: 1024px) {
      padding: 98px 0 50px;
    }

    &.faqs_topics {
      &.post {
        padding: 122px 0 34px;
      }

      padding: 122px 0 58px;
      @media all and (max-width: 1024px) {
        padding: 80px 0 30px;
      }
    }

    &_wrapper {
      display: flex;
      align-items: center;
    }

    &.faqs_topics {
      .banner_section_back {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: $black_text_color;
        font-family: $Arial-700;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin: 0;
        @media all and (max-width: 1024px) {
          font-size: 14px;
          line-height: 28px;
        }

        svg {
          display: block;
          width: 17.5px;
          height: auto;
          margin-right: 16px;
        }
      }

      .banner_section_title {
        text-align: start;
        margin: 42px 0 0;
        @media all and (max-width: 1024px) {
          margin: 20px 0 0;
        }
      }
    }

    &_title {
      color: $black_text_color;
      text-align: center;
      font-family: $Kameron-400;
      font-weight: 400;
      font-size: 64px;
      line-height: 64px;
      margin: 0 0 30px;
      @media all and (max-width: 1024px) {
        font-size: 36px;
        line-height: 36px;
      }
    }

    &_sub_title {
      color: #191919;
      margin: 0 auto 44px;
      max-width: 510px;
      text-align: center;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      @media all and (max-width: 1024px) {
        font-size: 16px;
        line-height: 20px;
        margin: 0 auto 30px;
        max-width: 480px;
      }
    }

    .search_input_wrapper {
      position: relative;
      margin: 0 auto;
      max-width: 480px;

      .search_input {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 72px;
        border-radius: 50px;
        border: 1px solid $black_text_color_70;
        background: $white_text_color_70;
        color: $black_text_color;
        outline: none;
        box-shadow: none;
        @media all and (max-width: 1024px) {
          padding: 15px 24px 15px 56px;
        }
      }

      .search_input_icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 34px;
        @media all and (max-width: 1024px) {
          left: 24px;
        }
      }
    }
  }
}

