.sign_up_form_data {
  .sign_up_form_columns {
    display: flex;
    margin-bottom: 40px;
    @media all and (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 20px;
    }

    .sign_up_form_left {
      flex: 1;
      padding-right: 20px;
      box-sizing: border-box;
      @media all and (max-width: 767px) {
        padding-right: 0;
      }
    }

    .sign_up_form_right {
      flex: 1;
      padding-left: 20px;
      box-sizing: border-box;
      @media all and (max-width: 767px) {
        padding-left: 0;
      }
    }

    .react-tel-input {
      .flag-dropdown {
        background: transparent;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: none;

        &.open .selected-flag {
          background: none;
        }

        .selected-flag {
          &:hover {
            background: none;
          }
        }

        .selected-flag {
          padding-left: 16px;
        }
      }

      .country-list {
        border-radius: 10px;

        .search {
          padding: 10px 14px 6px 2px;

          .search-emoji {
            display: none;

          }

          .search-box {
            padding: 4px 10px;
            font-size: 12px;

            &::placeholder {
              font-size: 12px;
            }
          }
        }

        .country-name, .dial-code {
          font-family: $Arial-400;
          color: $black_text_color;
        }
      }

      height: 44px;

      .form-control {
        height: 100%;
        padding: 10px 16px 10px 58px;
      }
    }
  }

  .account_type {
    border-radius: 10px;
    border: 1px solid rgba(25, 25, 25, 0.24);
    background: rgba(25, 25, 25, 0.02);
    margin: 0 0 14px;
    padding: 10px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &_logo {
      display: block;
      width: 48px;
      height: auto;
      margin: 0 12px 0 0;
    }

    &_info {
      display: flex;
      flex-direction: column;

      h3 {
        color: $black_text_color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-700;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        margin: 0 0 2px;
      }

      span {
        padding: 2px 12px;
        box-sizing: border-box;
        display: block;
        border-radius: 16px;
        background: linear-gradient(143deg, #D4FFED -3.31%, #FCFEBB 78.05%);
        color: $black_text_color;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        align-self: flex-start;
      }
    }
  }

  display: flex;
  flex-direction: column;
  width: 100%;

  .link_text {
    margin-bottom: 22px;
  }

  .cta {
    margin-bottom: 20px;
  }

  .links {
    .link_text {
      margin: 0;
    }
  }

  .muted_text {
    display: block;
  }

}