.what_is_add {
  &_wrapper {
    display: flex;
    margin: 0 -20px;
    justify-content: flex-end;
    padding: 0 0 82px;
    border-bottom: 1px solid $black_text_color_024;
    @media all and (max-width: 1024px) {
      padding: 0 0 50px;
    }
  }

  margin-bottom: 96px;
  @media all and (max-width: 1024px) {
    margin-bottom: 50px;
  }

  &.stocks {
    .what_is_add_item {
      padding-left: 0;
      @media all and (max-width: 767px) {
        width: 100%;
        padding-left: 20px;
      }
    }
  }

  &.commodities {
    .what_is_add_wrapper {
      @media all and (max-width: 767px) {
        flex-wrap: wrap;
      }
    }

    .what_is_add_item {
      &:first-child {
        @media all and (max-width: 767px) {
          margin-bottom: 32px;

        }
      }
      @media all and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &_item {
    padding: 0 20px;
    box-sizing: border-box;
    color: $black_text_color_70;
    font-family: $Arial-400;
    letter-spacing: 0.6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    width: 50%;
  }
}
