.banner_bg {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(51, 102, 255, 0.00) 0%, #36F 100%);
    opacity: 0.08;
    top: 0;
    left: 0;
  }

  .banner_section {
    padding: 134px 0 100px;
    z-index: 2;
    position: relative;
    @media all and (max-width: 1024px) {
      padding: 96px 0 30px;

    }

    &_wrapper {

    }

    &_title {
      color: $black_text_color;
      font-family: $Kameron-400;
      font-weight: 400;
      font-size: 64px;
      line-height: 64px;
      margin: 0 0 84px;
      @media all and (max-width: 1024px) {
        font-size: 36px;
        line-height: 36px;
        margin: 0 0 50px;
      }
    }

    &_switch {
      display: flex;
      margin: 0 -8px;
      @media all and (max-width: 767px) {
      flex-wrap: wrap;
      }

        &_item {
        padding: 0 8px;
        box-sizing: border-box;

        .switch_button {
          display: flex;
          text-decoration: none;
          padding: 8px 16px;
          box-sizing: border-box;
          color: $black_text_color;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          border-radius: 16px;
          background: rgba(25, 25, 25, 0.08);
          @media all and (max-width: 1024px) {
            font-size: 14px;
          }
          @media all and (max-width: 767px) {
            margin-bottom: 12px;
          }

          &.active {
            background: $blue_color;
            color: $white_text_color;
          }
        }
      }
    }
  }
}

