.banner_bg {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
  background: rgb(46, 85, 204);
  background: radial-gradient(at 50% bottom, rgba(46, 85, 204, 1) 0%, rgba(25, 25, 25, 1) 76%);
  @media all and (max-width: 1024px) {
    margin-bottom: 50px;
  }
  @media all and (max-width: 576px) {
    margin-bottom: 64px;
  }
}

.banner_section {
  padding: 134px 0 0;
  z-index: 2;
  position: relative;


  &_title {
    font-family: $Kameron-400;
    font-weight: 400;
    line-height: 76px;
    font-size: 64px;
    color: $white_text_color;
    max-width: 697px;
    margin: 0 auto 34px;
    text-align: center;

    @media all and (max-width: 1024px) {
      font-size: 36px;
      line-height: 36px;
      margin: 0 auto 20px;
      text-align: center;
    }
    @media all and (max-width: 576px) {
      max-width: 294px;
    }

    &_underline {
      text-decoration: underline;
    }
  }

  &_description {
    max-width: 573px;
    margin: 0 auto 45px;
    text-align: center;
    font-family: $Arial-400;
    letter-spacing: 0.6px;
    font-weight: 400;
    line-height: 28px;
    font-size: 20px;
    color: $white_text_color;
    @media all and (max-width: 1024px) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 auto 36px;
      text-align: center;
      max-width: 470px;
    }
    @media all and (max-width: 576px) {
      max-width: 250px;
    }

  }

  &_cta {
    margin: 0 auto 120px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    @media all and (max-width: 1024px) {
      margin: 0 auto 60px;
    }

    button {
      @media all and (max-width: 576px) {
        width: 100%;
      }
    }

    @media all and (max-width: 576px) {
      max-width: 294px;
      width: 100%;
    }
  }


  &_media {
    overflow: hidden;
    position: relative;
    max-width: 1060px;
    margin: 0 auto;
    @media all and (max-width: 576px) {
      padding-top: 60px;
      box-sizing: border-box;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      object-fit: cover;
      margin: 0 auto 0;
      max-width: 1060px;
    }

    .label_img {
      position: absolute;
      display: block;
      width: 100%;
      height: auto;

      &.lt {
        top: 100px;
        left: -2%;
        max-width: 341px;
        width: 27%;
        @media all and (max-width: 576px) {
          width: 100%;
          top: -3%;
          max-width: 204px;
        }
      }

      &.lb {
        bottom: 50px;
        left: 1%;
        max-width: 227px;
        width: 18%;
        @media all and (max-width: 576px) {
          width: 100%;
          top: 50%;
          max-width: 140px;
          left: -4%;
        }
      }

      &.ct {
        top: 41%;
        left: 44%;
        max-width: 77px;
        width: 6%;
        @media all and (max-width: 576px) {
          top: initial;
          left: 44%;
          max-width: 40px;
          width: 100%;
          bottom: 32%;
        }
      }

      &.c {
        top: 42%;
        left: 37%;
        max-width: 248px;
        width: 20%;
        @media all and (max-width: 576px) {
          width: 100%;
          top: initial;
          bottom: -6%;
          max-width: 130px;
          left: 30%;
        }
      }

      &.rt {
        top: 21%;
        right: -2%;
        max-width: 330px;
        width: 25%;
        @media all and (max-width: 576px) {
          width: 100%;
          top: -3%;
          max-width: 150px;
          right: 1%;
        }
      }
    }
  }
}

