.trade_section {
  padding: 96px 0 122px;
  background: $blue_color_04;
  @media all and (max-width: 1024px) {
    padding: 70px 0;
  }

  &_wrapper {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: stretch;
    @media all and (max-width: 1024px) {
      justify-content: flex-start;
    }
    @media all and (max-width: 767px) {
      flex-wrap: wrap;
    }

  }

  &_media {
    width: 50%;
    max-width: 510px;
    position: relative;
    @media all and (max-width: 1024px) {
      max-width: 272px;
    }
    @media all and (max-width: 767px) {
      max-width: none;
      box-sizing: border-box;
      padding: 0 60px;
      width: 100%;
      order: 2;
    }

    @media all and (max-width: 576px) {
      padding: 0 16px;
    }

    .man_working {
      width: 100%;
      height: auto;
      display: block;
    }

    .stock {
      position: absolute;
      max-width: 368px;
      width: 73%;
      height: auto;
      display: block;
      bottom: 30px;
      right: -30%;
      @media all and (max-width: 767px) {
        right: -2%;
      }
      @media all and (max-width: 576px) {
        width: 60%;
        right: -4%;
      }
    }

    .trust {
      position: absolute;
      max-width: 138px;
      width: 27%;
      height: auto;
      display: block;
      bottom: 5%;
      left: 5%;
    }
  }

  &_description {
    padding-left: 140px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media all and (max-width: 1024px) {
      padding-left: 130px;
    }

    @media all and (max-width: 767px) {
      order: 1;
      margin-bottom: 40px;
      padding: 0 16px;
    }


    &_title {
      color: $black_color;
      font-family: $Kameron-400;
      font-weight: 400;
      font-size: 32px;
      line-height: 36px;
      margin: 0 0 40px;
      max-width: 400px;
      @media all and (max-width: 1024px) {
        font-size: 24px;
        line-height: 28px;
        max-width: 276px;
      }
    }

    &_list {
      padding: 0 0 0 40px;
      margin: 0 0 40px;
      @media all and (max-width: 1024px) {
        margin: 0;
      }

      .list_item {
        color: $black_color;
         font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        position: relative;
        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 24px;
        }

        &:after {
          left: -40px;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          content: '';
          height: 20px;
          width: 20px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAyMEMxNS41MjI4IDIwIDIwIDE1LjUyMjggMjAgMTBDMjAgNC40NzcxNSAxNS41MjI4IDAgMTAgMEM0LjQ3NzE1IDAgMCA0LjQ3NzE1IDAgMTBDMCAxNS41MjI4IDQuNDc3MTUgMjAgMTAgMjBaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfMTQyXzQ4ODkpIi8+CjxyZWN0IHg9IjkuMDgxOCIgeT0iMTQuNjUyOCIgd2lkdGg9IjEuNTM4NDYiIGhlaWdodD0iMTAuNjgyNSIgcng9IjAuNzY5MjMxIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTQwLjY1OSA5LjA4MTggMTQuNjUyOCkiIGZpbGw9IiMxOTE5MTkiLz4KPHJlY3QgeD0iMTAuMDU1IiB5PSIxMy41ODIzIiB3aWR0aD0iMS41Mzg0NiIgaGVpZ2h0PSI2LjE1Mzg1IiByeD0iMC43NjkyMzEiIHRyYW5zZm9ybT0icm90YXRlKDEzNSAxMC4wNTUgMTMuNTgyMykiIGZpbGw9IiMxOTE5MTkiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl8xNDJfNDg4OSIgeDE9Ii0wLjk2IiB5MT0iLTAuNDQiIHgyPSIxMi42OCIgeTI9IjE3LjgiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0Q0RkZFRCIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGQ0ZFQkIiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .trade_button {
      text-decoration: none;
      @media all and (max-width: 1024px) {
        display: none;
      }
    }
  }
}