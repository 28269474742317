body.webp {
  .banner_section {
  }
}

.banner_bg {
  //background-image: linear-gradient(180deg, #0348A5 0%, #03238B 100%);
  position: relative;
  overflow: hidden;
  background: rgb(3, 72, 165);

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 120%;
    background-image: radial-gradient(circle, rgb(0, 13, 39) 24%, rgb(3, 72, 165) 100%);
    right: 0;
    bottom: -20%;
    @media all and (max-width: 767px){
      background-image: radial-gradient(at bottom right, rgb(0, 13, 39) 24%, rgb(3, 72, 165) 100%);
    }
  }

  &_blur {
    position: absolute;
    width: 100%;
    display: block;
    bottom: 0;
  }
}

.banner_section {
  padding: 141px 0 0;
  z-index: 2;
  position: relative;
  @media all and (max-width: 768px) {
    padding: 114px 0 0;
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media all and (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .main_banner {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-right: 40px;
      padding-bottom: 110px;
      box-sizing: border-box;
      justify-content: center;
      @media all and (max-width: 1200px) {
        padding-bottom: 70px;
      }

      @media all and (max-width: 767px) {
        width: 100%;
        margin-bottom: 34px;
        padding-bottom: 0;
        padding-right: 0;
        align-items: center;
      }
      @media all and (max-width: 576px) {
        margin-bottom: 40px;
      }

      &_title {
        max-width: 425px;
        margin: 0 0 26px;
        font-family: $Kameron-400;
        font-weight: 400;
        line-height: 76px;
        font-size: 72px;
        color: $white_text_color;

        @media all and (max-width: 1200px) {
          font-size: 52px;
          line-height: 56px;
        }

        @media all and (max-width: 1024px) {
          margin: 0 0 20px;
          max-width: 280px;
          font-size: 36px;
          line-height: 36px;
        }

        @media all and (max-width: 767px) {
          text-align: center;
          max-width: 260px;
        }
      }

      &_sub_title {
        max-width: 420px;
        margin: 0 0 60px;
         font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        line-height: 28px;
        font-size: 20px;
        color: $white_text_color;
        @media all and (max-width: 1024px) {
          margin: 0 0 32px;
          font-size: 16px;
          max-width: 218px;
        }
        @media all and (max-width: 767px) {
          text-align: center;
        }
      }

      &_cta {
        display: flex;
        margin: 0 -10px 55px;

        @media all and (max-width: 1024px) {
          margin: 0 -10px 36px;
        }

        @media all and (max-width: 576px) {
          flex-direction: column;
          width: 100%;
        }

        a {
          text-decoration: none;
          padding: 0 10px;
          box-sizing: border-box;

          @media all and (max-width: 1024px) {
            button {
              svg {
                display: none;
              }
            }
          }

          @media all and (max-width: 767px) {
            text-align: center;
            button {
              svg {
                display: block;
              }
            }
          }

          &:first-child {
            @media all and (max-width: 576px) {
              margin: 0 0 16px;
              padding: 0;
              button {
                width: 100%;
              }
            }
          }

          &:last-child {
            @media all and (max-width: 576px) {
              padding: 0;
              button {
                width: 100%;
              }
            }
          }

          @media all and (max-width: 576px) {
            width: 100%;
          }
        }
      }

      &_platforms {
        display: flex;
        align-items: center;

        .platforms_text {
          color: $white_text_color_70;
           font-family: $Arial-400;
        letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin: 0 9px 0 0;
        }

        .platform_item {
          padding: 0 9px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
        }
      }
    }

    .media_banner {
      &_blur {
        position: absolute;
        background: #191919;
        opacity: 0.85;
        mix-blend-mode: multiply;
        filter: blur(250px);
        display: block;
        width: 1152.176px;
        height: 1743.142px;
        transform: rotate(-30deg);
        flex-shrink: 0;
        right: -220px;
        top: -200px;
        z-index: -1;
      }

      position: relative;
      width: 50%;
      margin: 0 80px 0 auto;
      @media all and (max-width: 1024px) {
        margin: 0 40px 0 auto;
      }
      @media all and (max-width: 767px) {
        width: 100%;
        max-width: none;
        padding: 0 80px;
        margin: 0;
        box-sizing: border-box;
      }

      @media all and (max-width: 767px) {
        padding: 0 40px;
      }


      &_wrapper {
        border-radius: 24px;
        position: relative;
      }

      &_main {
        display: block;
        width: 100%;
        margin: 0 auto;
        height: auto;
        max-width: 438px;

        @media all and (max-width: 767px) {
          width: 100%;
          max-width: 440px;
        }
      }

      &_assets {
        position: absolute;
        display: block;
        right: -21%;
        top: 26%;
        width: 61%;
        max-width: 337px;
        height: auto;
      }

      &_social {
        position: absolute;
        display: block;
        left: -14%;
        bottom: 13%;
        width: 61%;
        max-width: 337px;
        height: auto;
        @media all and (max-width: 1024px) {
          bottom: 29%;
        }
      }

      &_spreads {
        position: absolute;
        display: block;
        left: -14%;
        top: 9%;
        width: 61%;
        max-width: 337px;
        height: auto;
      }

      &_02t {
        position: absolute;
        display: block;
        right: -5%;
        top: 9%;
        width: 100%;
        height: auto;
        max-width: 84px;
        @media all and (max-width: 1024px) {
          max-width: 48px;
        }
      }
    }
  }
}

