.banner_bg {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(51, 102, 255, 0.00) 0%, #36F 100%);
    opacity: 0.08;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .banner_section {
    padding: 137px 0 367px;
    z-index: 2;
    position: relative;

    @media all and (max-width: 1024px) {
      padding: 96px 0 367px;
    }

    &_title {
      color: $black_text_color;
      font-family: $Kameron-400;
      font-weight: 400;
      font-size: 64px;
      line-height: 64px;
      margin: 0 0 30px;
      text-align: center;
      @media all and (max-width: 1024px) {
        font-size: 36px;
        line-height: 36px;
      }
    }

    &_sub_title {
      color: $black_text_color;
      text-align: center;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin: 0 auto;
      max-width: 510px;
      @media all and (max-width: 1024px) {
        font-size: 16px;
        line-height: 20px;
        max-width: 410px;
      }
    }
  }
}

