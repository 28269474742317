body.webp {

  .what_is_section {

    &_wrapper {

      .what_is_media {
        background-image: url("/img/pages/trade/forex/what_is/forex_bg.webp");

        &.crypto {
          background-image: url("/img/pages/trade/crypto/what_is/crypto_bg.webp");
        }
      }
    }
  }
}


.what_is_section {
  margin-bottom: 130px;
  @media all and (max-width: 1024px) {
    margin-bottom: 70px;
  }

  &.commodities, &.stocks {
    margin-bottom: 70px;
  }

  &_wrapper {
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    &.categories {
      box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
    }
    @media all and (max-width: 767px) {
      flex-direction: column;
    }

    .what_is_media {
      overflow: hidden;
      position: relative;
      background-size: cover;
      background-position: center;
      width: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 30px 30px 0;
      background-image: url("/img/pages/trade/forex/what_is/forex_bg.png");
      box-sizing: border-box;
      @media all and (max-width: 767px) {
        width: 100%;
      }

      &.forex {
        .what_is_img_add {
          width: 150%;
          @media all and (max-width: 1024px) {
            left: -40px;
          }
        }
      }

      &.categories {
        background-color: #fff;
        align-items: center;
        background-size: cover;
        background-position: center;
        background-image: url("/img/pages/education_center/tutorials_bg.png");

        .what_is_img {
          max-width: 480px;
        }
        .what_is_img_add {
          width: 150%;
          @media all and (max-width: 1024px) {
            left: -40px;
          }
        }
      }

      &.crypto {
        background-image: url("/img/pages/trade/crypto/what_is/crypto_bg.png");

        .what_is_img {
          max-width: 321px;
        }

        .what_is_img_add {
          width: 72%;
        }
      }

      &.indices {
        background-image: url("/img/pages/trade/crypto/what_is/crypto_bg.png");

        .what_is_img {
          width: 100%;
          max-width: none;
        }

        .what_is_img_add {
          width: 79%;
        }
      }

      &.commodities {
        background-image: url("/img/pages/trade/crypto/what_is/crypto_bg.png");
        @media all and (max-width: 767px) {
          padding: 30px 30px 30px 0;
          justify-content: flex-start;
        }

        .what_is_img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          @media all and (max-width: 767px) {
            position: static;
            transform: none;
          }
        }

        .what_is_img_add {
          left: initial;
          right: 0;
          width: 61%;
        }
      }

      &.stocks {
        background-image: url("/img/pages/trade/crypto/what_is/crypto_bg.png");
        @media all and (max-width: 767px) {
          padding: 0;
          justify-content: flex-start;
        }

        .what_is_img {
          position: absolute;
          max-width: 640px;
          bottom: 0;
          left: 0;
          @media all and (max-width: 767px) {
            position: static;
          }
        }

        .what_is_img_add {
          width: 150%;
          @media all and (max-width: 767px) {
            left: -10%;
          }
        }
      }

      .what_is_img {
        position: relative;
        z-index: 2;
        object-fit: cover;
        max-width: 460px;
        margin: 0 auto;
        width: 100%;
        display: block;
        height: auto;
        object-position: bottom;
      }

      .what_is_img_add {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        height: auto;
        display: block;
        object-position: bottom;
      }
    }

    .what_is_description {
      width: 50%;
      padding: 108px 32px 108px 112px;
      box-sizing: border-box;
      background: $black_text_color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media all and (max-width: 1200px) {
        padding: 40px 30px 40px 40px;
      }
      @media all and (max-width: 767px) {
        width: 100%;
      }

      &.categories {

        background: #fff;
        .what_is_description_body {
          margin-bottom: 30px;
          color: $black-text_color;
        }
        .what_is_description_title {
          color: $black-text_color;
        }
        .what_is_description_list {
          .list_item {
            color: $black-text_color;
          }
        }
      }

      &_title {
        color: $white_text_color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Kameron-400;
        font-weight: 400;
        font-size: 36px;
        line-height: 36px;
        margin: 0 0 45px;
        max-width: 370px;
        @media all and (max-width: 1024px) {
          font-size: 24px;
          line-height: 28px;
          margin: 0 0 30px;
        }
      }

      &_body {
        color: $white_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 28px;
        max-width: 410px;

        &:last-of-type {
          margin: 0;
        }

        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 24px;
        }

        &_cta {
          color: $white_text_color;
          padding-right: 10px;
        }
      }

      &_list {
        display: flex;
        flex-direction: column;
        padding-left: 42px;
        box-sizing: border-box;
        margin-bottom: 70px;

        .list_item {
          color: $white_text_color;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          position: relative;
          @media all and (max-width: 1024px) {
            font-size: 16px;
            line-height: 24px;
          }

          &:after {
            left: -40px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            content: '';
            height: 20px;
            width: 20px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAyMEMxNS41MjI4IDIwIDIwIDE1LjUyMjggMjAgMTBDMjAgNC40NzcxNSAxNS41MjI4IDAgMTAgMEM0LjQ3NzE1IDAgMCA0LjQ3NzE1IDAgMTBDMCAxNS41MjI4IDQuNDc3MTUgMjAgMTAgMjBaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfMTQyXzQ4ODkpIi8+CjxyZWN0IHg9IjkuMDgxOCIgeT0iMTQuNjUyOCIgd2lkdGg9IjEuNTM4NDYiIGhlaWdodD0iMTAuNjgyNSIgcng9IjAuNzY5MjMxIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTQwLjY1OSA5LjA4MTggMTQuNjUyOCkiIGZpbGw9IiMxOTE5MTkiLz4KPHJlY3QgeD0iMTAuMDU1IiB5PSIxMy41ODIzIiB3aWR0aD0iMS41Mzg0NiIgaGVpZ2h0PSI2LjE1Mzg1IiByeD0iMC43NjkyMzEiIHRyYW5zZm9ybT0icm90YXRlKDEzNSAxMC4wNTUgMTMuNTgyMykiIGZpbGw9IiMxOTE5MTkiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl8xNDJfNDg4OSIgeDE9Ii0wLjk2IiB5MT0iLTAuNDQiIHgyPSIxMi42OCIgeTI9IjE3LjgiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0Q0RkZFRCIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGQ0ZFQkIiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
}