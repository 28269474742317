.features_section {
  padding: 62px 0;
  @media all and (max-width: 1024px) {
    padding: 50px 0;
  }
  margin-bottom: 0;

  &_items {
    margin: 0 -10px;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 767px) {
      flex-wrap: wrap;
    }

    .features_section_item {
      display: flex;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;
      @media all and (max-width: 767px) {
        width: 50%;
        &:nth-child(1) {
          margin-bottom: 30px;
        }
        &:nth-child(2) {
          margin-bottom: 30px;
        }
      }


      .item_icon {
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue_color_08;
        width: 88px;
        height: 88px;
        margin-right: 20px;
        @media all and (max-width: 1024px) {
          min-width: 48px;
          max-width: 48px;
          min-height: 48px;
          max-height: 48px;

          img {
            width: 20px;
            height: 20px;
          }
        }

        img {
          display: block;
        }
      }

      .item_title {
         font-family: $Arial-400;
        letter-spacing: 0.6px;font-weight: 400;
        font-size: 21px;
        line-height: 24px;
        color: $black_color;
        max-width: 140px;

        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}