.store_button {
  padding: 16px 30px;
  box-sizing: border-box;
  border-radius: 50px;
  border: 1px solid $black_text_color;
  display: flex;
  align-items: center;
  background: $white_text_color;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: #191919;
    transition: all 0.3s ease-in-out;
    .store_button_text {
      .text, .store {
        transition: all 0.3s ease-in-out;
        color: white;
      }
    }
    svg {
      path {
        transition: all 0.3s ease-in-out;
        fill: white;
      }
    }
  }
  @media all and (max-width: 576px) {
    width: 100%;
  }

  svg {
    display: block;
    margin: 0 10px 0 0;
    path {
      transition: all 0.3s ease-in-out;
    }
  }

  &_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .text {
      transition: all 0.3s ease-in-out;
      color: $black_text_color;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 10px;
      line-height: normal;
    }

    .store {
      transition: all 0.3s ease-in-out;
      color: $black_text_color;
      font-family: $Arial-700;
      font-size: 18px;
      line-height: normal;
      @media all and (max-width: 576px) {
        font-size: 14px;
      }
    }
  }
}