.why_section {
  //background-image: linear-gradient(180deg, #0348A5 0%, #03238B 100%);
  //background-image: url("/img/pages/home/why/bg.svg");
  //background-size: cover;
  //background-position: center;
  position: relative;
  overflow: hidden;
  padding: 96px 0 150px;
  background: rgb(3, 72, 165);

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 120%;
    background-image: radial-gradient(at bottom left, rgb(0, 13, 39) 24%, rgb(3, 72, 165) 100%);
    right: 0;
    bottom: -20%;
    @media all and (max-width: 767px) {
      background-image: radial-gradient(at bottom right, rgb(0, 13, 39) 24%, rgb(3, 72, 165) 100%);
    }
  }

  @media all and (max-width: 1024px) {
    padding: 70px 0 90px;
  }

  &_wrapper {
    position: relative;
    z-index: 2;
  }

  &_title {
    color: #FFF;
    text-align: center;
    font-family: $Kameron-400;
    font-weight: 400;
    font-size: 54px;
    font-style: normal;
    line-height: 54px;
    margin: 0 0 56px;
    @media all and (max-width: 1024px) {
      font-size: 24px;
      line-height: 28px;
    }

    &_underline {
      text-decoration: underline;
    }
  }

  &_table {
    display: flex;

    .table_left {
      width: 100%;

      max-width: 400px;
      @media all and (max-width: 1024px) {
        max-width: 220px;
        padding-right: 16px;
        box-sizing: border-box;
      }
      @media all and (max-width: 767px) {
        width: 50%;
      }

      &_item {
        color: $white_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 20px;
        line-height: 56px;
        white-space: nowrap;
        @media all and (max-width: 1024px) {
          font-size: 14px;
          line-height: 46px;
        }
        @media all and (max-width: 767px) {
          font-size: 13px;
        }

        &:first-child {
          opacity: 0;
        }
      }
    }

    .table_right {
      display: flex;
      width: 100%;
      @media all and (max-width: 767px) {
        width: 50%;
      }

      .swiper {
        width: 100%;

        .swiper-slide {
          flex: 1;
          @media all and (max-width: 767px) {
            flex: none;
          }

          &:first-child {

            flex: 2;
            @media all and (max-width: 767px) {
              flex: none;
            }

          }

          &:last-child {
            .table_right_item_icon {
              border-right: 1px solid rgba(255, 255, 255, 0.24);
            }
          }
        }
      }

      &_item {
        padding: 0 20px 0;
        box-sizing: border-box;
        margin: 0 -20px 0;
        flex: 1;


        &_title {
          margin: 0 0 30px;
          color: $white_text_color;
          text-align: center;
          font-family: $Arial-700;
          font-size: 16px;
          font-style: normal;
          line-height: 48px;
          @media all and (max-width: 1024px) {
            font-size: 12px;
            margin: 0 0 10px;
          }

        }

        &_icon {
          padding: 0 0 30px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid rgba(255, 255, 255, 0.24);
          @media all and (max-width: 1024px) {
            padding: 0 0 27px;
          }

          img {
            @media all and (max-width: 1024px) {
              width: 20px;
              height: 20px;
            }
          }

          &:last-child {
            padding: 0;
          }
        }
      }
    }
  }
}