.instruments_section {
  background: $blue_color_04;
  padding: 100px 0 124px;
  margin-bottom: 122px;
  @media all and (max-width: 1024px) {
    padding: 70px 0;
    margin-bottom: 70px;
  }

  &_title {
    color: #191919;
    text-align: center;
    font-family: $Kameron-400;
    font-weight: 400;
    font-size: 54px;
    line-height: 54px;
    margin: 0 auto 50px;
    @media all and (max-width: 1024px) {
      margin: 0 0 30px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  &_buttons {
    margin: 0 auto 50px;
    max-width: 536px;
    display: flex;
    justify-content: center;
    @media all and (max-width: 1024px) {
      margin: 0 auto 20px;
    }
    @media all and (max-width: 576px) {
      flex-wrap: wrap;
    }

    &_button {
      padding: 6px 18px;
      border-radius: 16px;
      margin-right: 14px;
      color: $black_text_color;
      background: $black_text_color_08;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      border: none;
      box-sizing: border-box;
      cursor: pointer;
      @media all and (max-width: 1024px) {
        font-size: 14px;
        line-height: 20px;
        margin-right: 10px;
      }
      @media all and (max-width: 576px) {
        margin-bottom: 10px;
      }

      &.active {
        background: $blue_color;
        color: $white_text_color;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .search_input_wrapper {
    position: relative;
    margin: 0 0 60px;
    @media all and (max-width: 1024px) {
      margin: 0 0 50px;
    }

    .search_input {
      width: 100%;
      box-sizing: border-box;
      padding: 20px 72px;
      border-radius: 50px;
      border: 1px solid $black_text_color_70;
      background: $white_text_color_70;
      color: $black_text_color;
      outline: none;
      box-shadow: none;
      @media all and (max-width: 1024px) {
        padding: 15px 24px 15px 56px;
      }
    }

    .search_input_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 34px;
      @media all and (max-width: 1024px) {
        left: 24px;
      }
    }
  }

  .instrument_table {
    @media all and (max-width: 767px) {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    .table_header {
      display: flex;
      @media all and (max-width: 767px) {
        min-width: 670px;
      }

      &_item {
        background: $blue_color_06;
        border-right: 1px solid rgba(25, 25, 25, 0.24);
        color: $black_text_color_50;
        font-family: $Arial-700;
        font-size: 12px;
        line-height: 36px;
        letter-spacing: 0.48px;
        text-transform: uppercase;
        padding: 28px 28px 0;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        @media all and (max-width: 1024px) {
          white-space: initial;
          line-height: 16px;
          padding: 16px 16px 0;
        }

        &:first-child {
          max-width: 160px;
        }

        &:last-child {
          border-right: none;
        }

        &:nth-child(2) {
          flex: 2;
          @media all and (max-width: 1200px) {
            flex: 1;
          }
          @media all and (max-width: 576px) {
           flex: 2;
          }
        }

        &:nth-child(3), &:nth-child(4) {
          text-align: end;
        }
      }
    }

    .table_body {
      @media all and (max-width: 767px) {
        min-width: 670px;
      }

      margin-bottom: 18px;
      padding-bottom: 16px;
      box-sizing: border-box;

      .table_row {
        display: flex;

        &.empty_row {
          padding: 20px;
          display: flex;
          justify-content: center;
          color: $black_text_color;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 14px;
          line-height: 32px;
        }

        &:first-child {
          .table_row_item {
            padding: 28px 28px 0;
            @media all and (max-width: 1024px) {
              font-size: 12px;
              padding: 16px 16px 0;
            }
          }
        }

        &:last-child {
          border-bottom: 1px solid rgba(25, 25, 25, 0.24);
        }

        &_item {
          border-right: 1px solid rgba(25, 25, 25, 0.24);
          color: $black_text_color;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 14px;
          line-height: 32px;
          padding: 0px 28px 0;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          box-sizing: border-box;
          @media all and (max-width: 1024px) {
            font-size: 12px;
            padding: 0px 16px 0;
          }

          &:first-child {
            max-width: 160px;
            font-family: $Arial-700;
          }

          &:last-child {
            border-right: none;
          }

          &:nth-child(2) {
            flex: 2;
            @media all and (max-width: 1200px) {
              flex: 1;
            }
            @media all and (max-width: 576px) {
              flex: 2;
            }
          }

          &:nth-child(3), &:nth-child(4) {
            text-align: end;
          }
        }
      }
    }
  }

  .table_pagination {
    display: flex;
    justify-content: center;

    .page_item {
      color: $black_text_color;
      text-align: center;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 0 4px;
      cursor: pointer;
    }

    .active_page_item {
      border-bottom: 1px solid $black_text_color;
    }
  }
}