.get_started {
  padding: 90px 0 120px;
  @media all and (max-width: 1024px) {
    padding: 70px 0;
  }

  &_title {
    color: $black_text_color;
    text-align: center;
    font-family: $Kameron-400;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
    margin: 0 auto 68px;
    max-width: 510px;
    @media all and (max-width: 1024px) {
      margin: 0 0 50px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  &_wrapper {
    display: flex;
    margin: 0 -20px;
    align-items: stretch;
    @media all and (max-width: 1300px) {
      margin: 0 -10px;
    }
    @media all and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &_register {

    width: 50%;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
    @media all and (max-width: 1300px) {
      padding: 0 10px;
    }
    @media all and (max-width: 767px) {
      width: 100%;
      margin-bottom: 20px;
    }

    &_padding {
      background-image: url("/img/pages/home/get_started/bg.svg");
      background-size: cover;
      background-repeat: no-repeat;
      box-sizing: border-box;
      display: flex;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      height: 100%;
      overflow: hidden;
      @media all and (max-width: 1024px) {
        flex-wrap: wrap;
      }
    }

    .register_info {
      padding: 48px 0 48px 24px;
      width: 50%;
      margin-right: 58px;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 1300px) {
        width: 40%;
        margin-right: 20px;
      }
      @media all and (max-width: 1024px) {
        width: 100%;
        margin: 0 0 50px;
        flex-direction: row;
        padding: 30px 16px 0;
        justify-content: space-between;
      }

      .cta {
        margin-top: auto;
        text-decoration: none;
        @media all and (max-width: 1300px) {
          svg {
            display: none
          }
        }
        @media all and (max-width: 1024px) {
          margin: 0;
        }
      }
    }

    .register_media {
      padding: 48px 24px 0 24px;
      width: 50%;
      position: relative;
      @media all and (max-width: 1300px) {
        width: 60%;
        padding: 48px 24px 0 0;
        display: flex;
        align-items: flex-end;
      }
      @media all and (max-width: 1024px) {
        padding: 0 16px;
        width: 100%;
        max-width: 190px;
        margin: 0 auto;

      }

      &_shadow {
        position: absolute;
        right: -40%;
        bottom: 0;
        z-index: -1;
      }

      &_img {
        width: 100%;
        display: block;
        object-fit: contain;
        object-position: bottom;
        @media all and (max-width: 1300px) {
          height: auto;
        }
      }
    }
  }

  .right_group {
    display: flex;
    width: 50%;
    @media all and (max-width: 1024px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 767px) {
      width: 100%;
    }
  }

  &_deposit {
    flex: 1;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 20px;
    @media all and (max-width: 1300px) {
      padding: 0 10px;
    }
    @media all and (max-width: 1024px) {
      flex: none;
      width: 100%;
      margin-bottom: 20px;
    }

    &_padding {
      padding: 48px 24px;
      box-sizing: border-box;
      background: rgba(51, 102, 255, 0.06);
      border-radius: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 1024px) {
        padding: 30px 16px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    .get_started_item_title {
      @media all and (max-width: 1024px) {
        width: 100%;
      }
    }

    .get_started_item_description {
      @media all and (max-width: 1024px) {
        width: 50%;
        align-self: center;
      }
    }

    &_popup {
      margin-top: auto;
      border-radius: 20px;
      overflow: hidden;
      background: $white_text_color;
      box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
      @media all and (max-width: 1024px) {
        width: 50%;
        margin: 0;
      }

      .popup_header {
        padding: 14px 16px;
        background: rgba(51, 102, 255, 0.16);
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
        box-sizing: border-box;
        display: flex;

        span {
          display: block;
          height: 10px;
          width: 10px;
          margin-right: 6px;
          border-radius: 50%;
          background: #89A7FF;
        }
      }

      .popup_body {
        padding: 32px 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media all and (max-width: 1024px) {
          padding: 8px 16px;
          svg {
            display: block;
            width: 20px;
            height: auto;
          }
        }

        &_title {
          color: $black_color;
          text-align: center;
          font-family: $Arial-700;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.14px;
          margin: 8px 0 0;
        }
      }
    }
  }

  &_trading {
    flex: 1;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 20px;
    @media all and (max-width: 1300px) {
      padding: 0 10px;
    }
    @media all and (max-width: 1024px) {
      flex: none;
      width: 100%;
    }

    &_padding {
      padding: 48px 24px;
      box-sizing: border-box;
      background: rgba(51, 102, 255, 0.06);
      border-radius: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 1024px) {
        padding: 30px 16px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    .get_started_item_title {
      @media all and (max-width: 1024px) {
        width: 100%;
      }
    }

    .get_started_item_description {
      @media all and (max-width: 1024px) {
        width: 40%;
        align-self: center;
      }
    }

    &_list {
      display: flex;
      flex-wrap: wrap;
      margin: auto -5px -5px;
      justify-content: center;
      @media all and (max-width: 1024px) {
        width: 60%;
        margin: 0;
      }

      .list_item {
        padding: 10px 5px;
        box-sizing: border-box;
        @media all and (max-width: 1024px) {
          padding: 4px 2px;
        }

        &:nth-child(2) {
          .list_item_text {
            background: $blue_color;
            color: $white_text_color;
          }
        }

        &_text {
          padding: 6px 12px;
          box-sizing: border-box;
          border-radius: 16px;
          background: rgba(255, 255, 255, 0.80);
          box-shadow: 0px 2px 10px 0px rgba(51, 102, 255, 0.08);
          color: $black_color;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          @media all and (max-width: 1024px) {
            font-size: 11px;
          }
        }
      }
    }
  }

  .get_started_item_title {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $Arial-700;
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 22px;
    color: $black_color;
    @media all and (max-width: 1024px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .get_started_item_description {
    color: $black_color;
    font-family: $Arial-400;
    letter-spacing: 0.6px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 20px;
    @media all and (max-width: 1024px) {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      max-width: 124px;
    }
  }
}