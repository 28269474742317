.trading_hours {
  padding: 100px 0 150px;
  margin-bottom: 60px;
  background-image: url("/img/pages/trade/hours_bg.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media all and (max-width: 1024px) {
    padding: 70px 0 120px;
  }
  @media all and (max-width: 767px) {
    padding: 70px 0 70px;
  }

  &_title {
    color: $white_text_color;
    text-align: center;
    font-family: $Kameron-400;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
    margin: 0 0 26px;
    @media all and (max-width: 1024px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &_sub_title {
    text-align: center;
    color: $white_text_color;
    font-family: $Arial-400;
    letter-spacing: 0.6px;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    margin: 0 0 58px;
    @media all and (max-width: 1024px) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 30px;
    }
  }

  .trading_hours_table {
    .table_header {
      display: flex;
      margin: 0 -10px 20px;
      @media all and (max-width: 767px) {
        flex-wrap: wrap;
      }
      @media all and (max-width: 576px) {
        margin: 0 -4px 0;
      }

      &_column {
        flex: 1;
        padding: 0 10px;
        box-sizing: border-box;
        text-align: center;
        color: $white_text_color_50;
        font-family: $Arial-400;
        font-weight: 400;
        font-size: 14px;
        line-height: 48px;
        letter-spacing: 0.56px;
        text-transform: uppercase;
        @media all and (max-width: 1200px) {
          line-height: 20px;
        }
        @media all and (max-width: 576px) {
          padding: 0 4px;
        }

        &.first {
          text-align: start;
          flex: 3;
          min-width: 400px;
          @media all and (max-width: 1024px) {
            min-width: 260px;
          }
          @media all and (max-width: 767px) {
            display: none;
          }
        }

        &.last {
          text-align: end;
          flex: 2;
          @media all and (max-width: 1024px) {
            white-space: nowrap;
          }
          @media all and (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    .table_body {
      .table_row {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 -10px;
        @media all and (max-width: 767px) {
          flex-wrap: wrap;
        }
        @media all and (max-width: 576px) {
          margin: 0 -4px;
        }

        &_column {
          flex: 1;
          padding: 0 10px;
          box-sizing: border-box;
          margin-right: -1px;
          @media all and (max-width: 576px) {
            padding: 0 4px;
          }

          svg {
            display: block;
            margin: 0 auto;
          }

          &.first {
            text-align: start;
            min-width: 400px;
            flex: 3;
            color: $white_text_color;
            font-family: $Arial-400;
            letter-spacing: 0.6px;
            font-weight: 400;
            font-size: 20px;
            line-height: 56px;
            white-space: nowrap;
            @media all and (max-width: 1024px) {
              font-size: 14px;
              line-height: 50px;
              min-width: 260px;
            }
            @media all and (max-width: 767px) {
              flex: none;
              width: 100%;
              line-height: 40px;
            }
          }

          &.last {
            text-align: end;
            flex: 2;
            color: $white_text_color;
            font-family: $Arial-700;
            font-size: 20px;
            line-height: 56px;
            @media all and (max-width: 767px) {
              font-size: 14px;
              position: absolute;
              right: 0;
              top: -6px;
            }
          }

          &.work_time {
            padding: 4px 10px;
            background: $blue_color;
            @media all and (max-width: 576px) {
              padding: 4px 4px;
            }

            .time_bg {
              display: block;
              height: 18px;
            }

            &.start_time {
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
              position: relative;

              &:after {
                z-index: 2;
              }

              &.full_start {
                background: transparent;

                .time_bg {
                  position: absolute;
                  left: 40%;
                  border-top-left-radius: 50px;
                  border-bottom-left-radius: 50px;
                  top: -9px;
                  display: block;
                  height: 26px;
                  background: $blue_color;
                  width: 100%;
                }

                &:before {
                  left: calc(40% + 2px);
                  z-index: 2;
                }

                &:after {
                  left: calc(40% + 32px);
                  z-index: 2;
                }
              }

              &:before {
                position: absolute;
                content: '';
                width: 20px;
                height: 20px;
                left: 2px;
                top: 50%;
                transform: translateY(-50%);
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAyMEMxNS41MjI4IDIwIDIwIDE1LjUyMjggMjAgMTBDMjAgNC40NzcxNSAxNS41MjI4IDAgMTAgMEM0LjQ3NzE1IDAgMCA0LjQ3NzE1IDAgMTBDMCAxNS41MjI4IDQuNDc3MTUgMjAgMTAgMjBaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfNTAxXzIyNjkpIi8+CjxyZWN0IHg9IjkuMDgxNzkiIHk9IjE0LjY1MyIgd2lkdGg9IjEuNTM4NDYiIGhlaWdodD0iMTAuNjgyNSIgcng9IjAuNzY5MjMxIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTQwLjY1OSA5LjA4MTc5IDE0LjY1MykiIGZpbGw9IiMxOTE5MTkiLz4KPHJlY3QgeD0iMTAuMDU1MSIgeT0iMTMuNTgyMyIgd2lkdGg9IjEuNTM4NDYiIGhlaWdodD0iNi4xNTM4NSIgcng9IjAuNzY5MjMxIiB0cmFuc2Zvcm09InJvdGF0ZSgxMzUgMTAuMDU1MSAxMy41ODIzKSIgZmlsbD0iIzE5MTkxOSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzUwMV8yMjY5IiB4MT0iLTAuOTYiIHkxPSItMC40NCIgeDI9IjEyLjY4IiB5Mj0iMTcuOCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRDRGRkVEIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZDRkVCQiIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=");
              }

              &:after {
                position: absolute;
                display: flex;
                white-space: nowrap;
                top: 50%;
                transform: translateY(-50%);
                left: 32px;
                content: attr(data-time);
                color: $white_text_color;
                text-align: center;
                font-family: $Arial-400;
                font-weight: 400;
                font-size: 11px;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.56px;
                @media all and (max-width: 1024px) {
                  font-size: 10px;
                }
                @media all and (max-width: 576px) {
                  font-size: 8px;
                }
              }
              &_b {
                &:before {
                  z-index: 3;
                  left: -28%;
                }
                &:after {
                  left: 10px!important;
                }
                .time_bg {
                  position: absolute;
                  right: 30%;
                  border-top-left-radius: 50px;
                  border-bottom-left-radius: 50px;
                  top: -9px;
                  display: block;
                  height: 26px;
                  background: $blue_color;
                  width: 100%;
                }
              }
            }

            &.end_time {
              border-top-right-radius: 50px;
              border-bottom-right-radius: 50px;
              background: $blue_color;
              position: relative;
              &.full_end {
                background: transparent;

                .time_bg {
                  position: absolute;
                  right: 40%;
                  border-top-right-radius: 50px;
                  border-bottom-right-radius: 50px;
                  top: -9px;
                  display: block;
                  height: 26px;
                  background: #3366FF;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}