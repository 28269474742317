.why_section {
  margin-bottom: 130px;
  overflow: hidden;
  @media all and (max-width: 768px) {
    margin-bottom: 50px;
  }

  &_title {
    display: flex;
    justify-content: space-between;
    margin: 0 -20px 100px;
    @media all and (max-width: 1200px) {
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-start;
    }
    @media all and (max-width: 1024px) {
      margin: 0 -20px 50px;
    }
    @media all and (max-width: 576px) {
      justify-content: flex-start;
    }

    &_title {
      padding: 0 20px;
      box-sizing: border-box;
      color: $black_text_color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $Kameron-400;
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      margin: 0;
      max-width: 400px;
      @media all and (max-width: 1200px) {
        width: 50%;
        max-width: none;
      }
      @media all and (max-width: 1024px) {
        font-size: 24px;
        line-height: 28px;
      }
      @media all and (max-width: 576px) {
        width: 100%;
        max-width: 270px;
        margin: 0 0 24px;
      }
    }

    &_description {
      color: $black_text_color;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      padding: 0 20px;
      box-sizing: border-box;
      max-width: 450px;
      margin: 0;
      @media all and (max-width: 1200px) {
        width: 50%;
        max-width: none;
        margin: 0 0 30px;
      }
      @media all and (max-width: 1024px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media all and (max-width: 576px) {
        width: 100%;
        margin: 0 0 50px;
      }
    }

    &_cta {
      padding: 0 20px;
      box-sizing: border-box;
      text-decoration: none;
      margin: 0 0 0 auto;
      @media all and (max-width: 1200px) {
        width: 50%;

      }
      @media all and (max-width: 576px) {
        width: 100%;
        margin: 0;
      }
    }
  }

  &_wrapper {
    position: relative;
    min-height: 250px;
    width: calc(95vw);
    padding: 0 0 10px 0;
    box-sizing: border-box;
    overflow-x: scroll;
    display: flex;
    align-items: stretch;
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 2px solid $black_text_color_50;
    }
    @media all and (max-width: 1024px) {
      min-height: 193px;
      width: calc(88vw);
    }
    @media all and (max-width: 768px) {
      width: calc(100vw);
    }

    .why_item {
      overflow: hidden;
      border-radius: 20px;
      background-image: url("/img/pages/trade/overlay.svg");
      z-index: 1;
      background-size: cover;
      background-position: center;
      padding: 40px 30px;
      min-width: 360px;
      box-sizing: border-box;
      right: 0;
      margin-right: 40px;
      @media all and (max-width: 1024px) {
        padding: 20px;
        margin-right: 30px;
        min-width: 220px;
      }

      &_wrapper {
      }

      svg {
        display: block;
        margin-bottom: 42px;
        @media all and (max-width: 1024px) {
          width: 36px;
          height: auto;
        }
      }

      h3 {
        color: $black_text_color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-700;
        font-size: 24px;
        line-height: 28px;
        max-width: 256px;
        margin: 0;
        @media all and (max-width: 1024px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}