.faqs_list_section {
  padding: 54px 0 75px;
  @media all and (max-width: 1024px) {
    padding: 40px 0 70px;
  }

  .faqs_list {
    display: flex;
    @media all and (max-width: 767px) {
      flex-direction: column;
    }

    &.sticky {
      display: block;
      @media all and (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }

      .faqs_list_list {
        margin: -276px 0 0 440px;
        @media all and (max-width: 1024px) {
          margin: -223px 0 0 260px;
        }
        @media all and (max-width: 767px) {
          margin: 0;
        }
      }

      .faqs_list_category {
        width: 290px;
        margin-right: 0;
        position: sticky;
        top: 100px;
        @media all and (max-width: 1024px) {
          display: inline-flex;
          min-width: initial;
          width: auto;
        }
        @media all and (max-width: 767px) {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          padding: 0;
          position: static;
          margin: 0 -10px 50px;
        }

        .category {
          @media all and (max-width: 767px) {
            padding: 0 10px;
            box-sizing: border-box;
          }
        }
      }
    }

    ol {
      margin-bottom: 16px;
      padding-left: 20px;
      list-style: initial;
    }

    &_category {
      min-width: 290px;
      margin-right: 150px;
      display: flex;
      flex-direction: column;
      @media all and (max-width: 1200px) {
        margin-right: 0;
        padding-right: 40px;
        box-sizing: border-box;
      }
      @media all and (max-width: 767px) {
        min-width: 210px;
      }

      &_title {
        color: $black_text_color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Kameron-400;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        margin: 0;
        @media all and (max-width: 1024px) {
          font-size: 24px;
          line-height: 28px;
          max-width: 210px;
        }
        @media all and (max-width: 767px) {
          margin-bottom: 46px;
        }
      }

      .category {
        color: $black_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 20px;
        line-height: 48px;
        text-decoration: none;
        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 28px;
          margin: 0 0 10px;
        }

        &.active {
          text-decoration-line: underline;
        }
      }
    }

    &_list {
      .list_section {
        margin-bottom: 70px;
        @media all and (max-width: 1024px) {
          margin-bottom: 0;
        }

        &_title {
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Kameron-400;
          font-weight: 400;
          font-size: 36px;
          line-height: 36px;
          margin: 0 0 40px;
          @media all and (max-width: 1024px) {
            margin-bottom: 42px;
            font-size: 24px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

.faqs_categories_section {
  padding: 50px 0 30px;

  .faqs_categories_title {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $Kameron-400;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    margin: 0 0 48px;
    @media all and (max-width: 1024px) {
      font-size: 24px;
      line-height: 28px;
      margin: 0 0 15px;
    }
  }

  .categories_list {
    display: flex;
    margin: 0 -17px;
    flex-wrap: wrap;
    @media all and (max-width: 1024px) {
      margin: 0 -15px;
    }

    &_item {
      padding: 20px 17px;
      box-sizing: border-box;
      width: 33.33%;

      @media all and (max-width: 1024px) {
        width: 50%;
        padding: 15px;
      }
      @media all and (max-width: 576px) {
        width: 100%;
      }

      .item_wrapper {
        border-radius: 32px;
        background: $blue_color_06;
        padding: 40px 32px;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
        @media all and (max-width: 1024px) {
          padding: 30px;
        }

        img {
          display: block;
          margin-bottom: 50px;
          @media all and (max-width: 1024px) {
            width: 32px;
            height: auto;
            margin-bottom: 40px;
          }
        }

        h3 {
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-700;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
          margin: 0 0 14px;
          @media all and (max-width: 1024px) {
            font-size: 20px;
            margin: 0 0 12px;
          }
        }

        p, a {
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0 0 14px;
          @media all and (max-width: 1024px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        a {
          margin: auto 0 0;
        }
      }
    }
  }
}
