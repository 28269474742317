.features_section {
  margin-bottom: 100px;
  @media all and (max-width: 1024px) {
    margin-bottom: 70px;
  }

  &_wrapper {
    max-width: 1140px;
    margin: 0 auto;
  }

  &_items {
    margin: 0 -20px;
    overflow: hidden;
    display: flex;
    @media all and (max-width: 768px) {
      margin: 0 -16px;
    }
    @media all and (max-width: 576px) {
      flex-wrap: wrap;
      justify-content: center;
    }


    .feature_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;
      flex: 1;
      border-right: 1px solid rgba(25, 25, 25, 0.5);
      @media all and (max-width: 768px) {
        padding: 0 16px;
      }
      @media all and (max-width: 576px) {
        flex: none;
        width: 50%;
        border: none;
      }

      &:last-child {
        border-right: none;
        @media all and (max-width: 576px) {
          margin-top: 40px;
        }
      }

      &_icon {
        display: block;
        margin-bottom: 20px;
        @media all and (max-width: 1024px) {
          width: 32px;
        }
      }

      &_title {
        color: $black_color;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        margin: 0 auto 0;
        max-width: 258px;
        @media all and (max-width: 1024px) {
          font-size: 20px;
          line-height: 28px;
        }
        @media all and (max-width: 576px) {
          font-size: 18px;
        }
      }
    }
  }
}