@import "../variables";


.auth {
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgba(51, 102, 255, 0.00) 0%, #36F 100%);
    opacity: 0.08;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .risk_warning {
    color: $black_text_color_50;
    text-align: center;
    font-family: $Arial-400;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 0 32px;
    box-sizing: border-box;
    margin: 0;

    a {
      color: $black_text_color_50;
    }
  }

  @import "sign_in";
  @import "sign_up";
  @import "form";
  @import "header";
  @import "details";

}