.details_wrapper {

  .progress {
    display: flex;
    margin: 0 -20px 48px;
    justify-content: center;
    align-items: flex-end;

    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;

      &.current {
        .progress_item_icon {
          border-color: $blue_color;
          background: $blue_color;
        }

        a {
          font-family: $Arial-700;
          text-decoration: underline;
        }
      }

      &.complete {
        .progress_item_icon {
          border-color: $blue_color;
          background: $blue_color;
          height: 20px;
          width: 20px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iNS4wODE3OSIgeT0iOS42NTI4MyIgd2lkdGg9IjEuNTM4NDYiIGhlaWdodD0iMTAuNjgyNSIgcng9IjAuNzY5MjMxIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTQwLjY1OSA1LjA4MTc5IDkuNjUyODMpIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB4PSI2LjA1NTA1IiB5PSI4LjU4MjUyIiB3aWR0aD0iMS41Mzg0NiIgaGVpZ2h0PSI2LjE1Mzg1IiByeD0iMC43NjkyMzEiIHRyYW5zZm9ybT0icm90YXRlKDEzNSA2LjA1NTA1IDguNTgyNTIpIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
            background-size: 60%;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }

      &_icon {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border: 1px solid $black_text_color_50;
      }

      a {
        display: block;
        color: $black_text_color;
        text-align: center;
        font-family: $Arial-400;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        text-decoration: none;
      }
    }
  }

  .details_form {
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgba(25, 25, 25, 0.12);
    background: #FFF;
    box-shadow: 0px 10px 40px 0px rgba(51, 102, 255, 0.08);
    padding: 48px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    max-width: 920px;


    &.personal {
    }

    .form_title_wrapper {
      display: flex;
      justify-content: space-between;
    }

    .form_title {
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $Arial-700;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin: 0 0 40px;
    }
  }

  .form_bottom_cta {
    display: flex;

    .estimated_time {
      width: 50%;
      display: flex;
      align-items: center;

      svg {
        display: block;
        margin-right: 14px;
      }

      p {
        margin: 0;
        display: flex;
        flex-direction: column;
        color: $black_text_color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .cta_block {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_secured {
        display: flex;
        align-items: center;
        margin-right: 12px;

        svg {
          display: block;
          margin-right: 5px;
        }

        span {
          display: flex;
          flex-direction: column;
          color: $black_text_color_50;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .main_button {
        font-size: 16px;
        max-height: 48px;
      }

    }
  }
}
