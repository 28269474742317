.platforms_section {
  padding: 104px 0 0;
  position: relative;
  background-image: url("/img/pages/home/platforms/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  @media all and (max-width: 1024px) {
    padding: 70px 0 0;
  }

  &_title {
    color: $black_text_color;
    text-align: center;
    font-family: $Kameron-400;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
    margin: 0 0 100px;
    @media all and (max-width: 1024px) {
      font-size: 24px;
      line-height: 28px;
      margin: 0 0 70px;
    }
    @media all and (max-width: 767px) {
      margin: 0 0 50px;
    }
  }

  .slider_buttons_wrapper {

    .slider_buttons {
      display: flex;
      justify-content: space-between;
      margin: 0 -16px 68px;
      @media all and (max-width: 1024px) {
        margin: 0 -16px 32px;
      }
      @media all and (max-width: 767px) {
        overflow: auto;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }

      &_button {
        padding: 0 16px 38px;
        box-sizing: border-box;
        border-bottom: 4px solid transparent;
        @media all and (max-width: 1024px) {
          padding: 0 16px 20px;
        }

        &:first-child {
          button {
            justify-content: flex-start;
          }
        }

        &:last-child {
          button {
            justify-content: flex-end;
          }
        }

        &.active {
          border-color: $black_text_color;
        }

        button {
          cursor: pointer;
          border: none;
          background: transparent;
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-700;
          font-size: 24px;
          line-height: 24px;
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0;
          @media all and (max-width: 1024px) {
            font-size: 16px;
            line-height: 16px;
          }

          img {
            display: block;
            margin-right: 12px;
            @media all and (max-width: 1024px) {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }
  }

  .platforms_swiper_wrapper {
    .platform_item {
      display: flex;
      @media all and (max-width: 767px) {
        flex-direction: column;
      }

      &_body {
        margin-right: 74px;
        display: flex;
        flex-direction: column;
        @media all and (max-width: 767px) {
          flex-direction: row;
          justify-content: space-between;
          margin-right: 0;
        }

        &_text {
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          margin: 0 0 30px;
          max-width: 253px;
          @media all and (max-width: 1024px) {
            font-size: 16px;
            line-height: 24px;
            max-width: 156px;
          }
        }

        &_cta {
          text-decoration: none;
          margin: auto 0;
          @media all and (max-width: 767px) {
            margin: 0;
          }
        }
      }

      &_media {
        flex: 1;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}