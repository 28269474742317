.accordion_container {
  width: 100%;

  .accordion_data {
    .accordion_item_wrapper {
      border-bottom: 1px solid $black_text_color_50;
      transition: all 0.3s ease-in-out;
      position: relative;
      margin-bottom: 20px;
      @media all and (max-width: 1024px) {
        margin-bottom: 16px;
      }

      &.is_active {
        .accordion_item {
          transition: margin-bottom 0.3s ease;

          &_icon {
            transition: transform 0.5s ease;
            color: #000;
          }
        }

        .accordion_item_body {
          transition: height 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
          transform-origin: top;
          opacity: 1;
          display: block;
          transform: scaleY(1);
          overflow: hidden;
          padding: 0 0 20px;
          height: auto;
          color: $black_text_color;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 16px;
          font-style: normal;
          line-height: 24px;
          @media all and (max-width: 1024px){
            font-size: 14px;
          }

          p {
            margin: 0 0 16px;
          }

          .bold {
            font-size: 16px;
            font-family: $Arial-700;
            font-weight: 700;
            margin: 0;
          }
        }
      }

      .accordion_item {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: margin-bottom 0.3s ease;

        &_title {
          color: $black_text_color;
          font-family: $Arial-700;
          font-size: 20px;
          margin: 0;
          @media all and (max-width: 1024px) {
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 10px;
          }
        }

        &_icon {
          transition: color 0.3s ease-in-out, transform 0.5s ease;
          font-size: 34px;
          font-family: $Kameron-400;
          font-weight: 400;
          color: $black_text_color;
          width: 42px;
          height: 42px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          box-sizing: border-box;
          padding-bottom: 2px;
          @media all and (max-width: 1024px) {
            width: 20px;
            height: 20px;
          }
        }
      }

      @media all and (min-width: 768px) {
        &:not(.is_active) {
          .accordion_item:hover {
            .accordion_item_title, .accordion_item_icon {
              transition: color 0.3s ease-in-out, transform 0.5s ease;
              //color: $green_text_color;
            }
          }
        }
      }

      .accordion_item_body {
        transition: height 0.3s ease, transform 0.3s ease, margin .3s ease;
        font-family: $Kameron-400;
        font-weight: 400;
        color: $white_text_color_50;
        height: 0;
        padding: 0 20px;
        transform: scaleY(0);
        opacity: 0;
        transform-origin: top;
        box-sizing: border-box;
        font-size: 20px;
        line-height: 140%;
        @media all and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
}
