.still_need {
  margin-bottom: 90px;
  @media all and (max-width: 1024px) {
    margin-bottom: 70px;
  }

  &_wrapper {
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    @media all and (max-width: 576px) {
      flex-direction: column;
    }
  }

  &_description {
    position: relative;
    width: 50%;
    padding: 20px 100px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media all and (max-width: 1024px) {
      padding: 40px 30px;
    }
    @media all and (max-width: 576px) {
      width: 100%;
      padding: 40px 30px 60px;

    }

    &:after {
      left: 0;
      top: 0;
      z-index: -1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: linear-gradient(143deg, #D4FFED -3.31%, #FCFEBB 78.05%);

    }

    .title {
      color: $black_text_color;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $Kameron-400;
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
      margin: 0 0 40px;
      @media all and (max-width: 1024px) {
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 24px;
      }

      a {
        color: $black_text_color;
        padding-left: 10px;
      }
    }

    .text {
      color: $black_text_color;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      margin: 0;
      @media all and (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &_media {
    width: 50%;
    @media all and (max-width: 576px) {
      width: 100%;
    }

    &_img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}