.payment_methods {
  .title_group {
    @media all and (max-width: 1024px) {
      margin-bottom: 50px;
    }
  }

  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: -1;
  display: none;

  &.visible {
    display: flex;
    position: static;
    opacity: 1;
    height: auto;
    z-index: 0;
  }
}