.risk {
  p {
    color: $black_text_color;
    font-family: $Arial-400;
    letter-spacing: 0.6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 30px;
    a {
      text-decoration: underline;
      color: $black_text_color_50;
    }
    .bold_font {
      font-family: $Arial-700;
    }
  }
  .big_font {
    font-size: 18px;
    margin: 0 0 50px;
  }
}