.main_button {
  padding: 18px 26px;
  border: 1px solid;
  color: $white_text_color;
  font-family: $Arial-400;
  letter-spacing: 0.6px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 50px;
  white-space: nowrap;

  @media all and (max-width: 1200px) {
    font-size: 16px;
    padding: 10px 26px;
  }

  svg {
    margin-left: 24px;
    display: block;

    path {
      transition: all 0.3s ease-in-out;
    }
  }

  &.transparent {
    background: transparent;

    &:hover {
      transition: all 0.3s ease-in-out;
      border-color: $blue_color;
    }
  }

  &.blue {
    &.black {
      background: $black_text_color;
      border-color: $black_text_color;

      &:hover {
        transition: all 0.3s ease-in-out;
        background: $white_text_color;
        color: $black_text_color;

        svg {
          path {
            stroke: $black_text_color;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    background: $blue_color;
    border-color: $blue_color;
    &.blue.dark_bg {
      &:hover {
        transition: all 0.3s ease-in-out;
        background: $white_text_color;
        color: $black_text_color;
        border-color: $white_text_color;


        svg {
          path {
            stroke: $black_text_color;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    &:hover {
      transition: all 0.3s ease-in-out;
      background: $black_text_color;
      color: $white_text_color;
      border-color: $black_text_color;


      svg {
        path {
          stroke: $white_text_color;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}