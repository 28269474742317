.auth_header {
  padding: 30px 0;

  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .auth_logo {
    display: block;
    width: 100%;
    max-width: 168px;
  }

  //.lang_list {
  //  position: relative;
  //
  //  &:hover {
  //    cursor: pointer;
  //
  //    .lang_hidden_wrapper {
  //      display: block;
  //    }
  //  }
  //
  //  .lang_hidden_wrapper {
  //    position: absolute;
  //    display: none;
  //    padding: 10px 0;
  //    bottom: -106px;
  //    right: 0;
  //    box-sizing: border-box;
  //
  //    .lang_hidden {
  //      background: #ecedef;
  //      padding: 10px 0;
  //      &_item {
  //        span {
  //          display: flex;
  //        }
  //      }
  //      .lang_hidden_item_flag {
  //        width: 20px;
  //        height: 20px;
  //      }
  //    }
  //
  //    li {
  //      padding: 10px;
  //      &:hover {
  //        background: #c3c3c4;
  //      }
  //
  //      a {
  //        display: flex;
  //        align-items: center;
  //        text-transform: capitalize;
  //        color: $black_text_color;
  //        font-family: $Arial-400;
  //        font-size: 16px;
  //        font-style: normal;
  //        font-weight: 400;
  //        line-height: normal;
  //        text-decoration: none;
  //      }
  //    }
  //  }
  //
  //  .lang_current {
  //    display: flex;
  //    align-items: center;
  //    text-transform: capitalize;
  //    color: $black_text_color;
  //    font-family: $Arial-400;
  //    font-size: 14px;
  //    font-style: normal;
  //    font-weight: 400;
  //    line-height: 40px;
  //
  //    .curr_img {
  //      margin-right: 12px;
  //      width: 20px;
  //      height: 20px;
  //      display: block;
  //
  //      path {
  //        transition: all 0.3s ease-in-out;
  //        fill: $black_text_color;
  //      }
  //    }
  //
  //    .lang_title {
  //      transition: all 0.3s ease-in-out;
  //      color: $black_text_color;
  //      display: flex;
  //    }
  //  }
  //}
  .lang_list {
    position: relative;

    .full_width {
      backdrop-filter: none;
      background: #fff;
      transform-origin: top;
      z-index: 1;
      transform: scaleY(0);
      position: absolute;
      right: 0;
      top: 50px;
      width: 250vw;
      left: -1500px;
      height: 170px;
      transition: all 0.3s ease-in-out;
      @media all and (max-width: 1024px) {
        top: 38px;
      }
      @media all and (max-width: 767px) {
        left: -900%;
      }
    }

    &:hover {
      .full_width {
        transform: scaleY(1);
        transition: all 0.3s ease-in-out;
      }

      .lang_hidden_wrapper {
        transform: scaleY(1);
        transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;
        opacity: 1;
      }

      .lang_current:after {
        transition: opacity 0.2s ease-in-out;
        opacity: 1;

      }
    }

    .href_lang {
      span {
        display: flex;
        align-items: center;
      }
    }

    .lang_current {
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        width: calc(100% - 40px);
        bottom: -16px;
        height: 3px;
        opacity: 0;
        left: 50%;
        transform: translateX(-50%);
        background: $blue_color;
        transition: opacity 0.2s ease-in-out;
        @media all and (max-width: 1024px) {
          bottom: -8px;
        }
        @media all and (max-width: 768px) {
          display: none;
        }
      }

      .curr_img {
        margin-right: 12px;
        position: relative;

        path {
          transition: all 0.3s ease-in-out;
        }
      }

      .lang_title {
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        color: $black_text_color;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
        display: block;
        text-transform: capitalize;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .lang_hidden_wrapper {
      display: flex;
      top: 100%;
      padding: 60px 0 20px;
      position: absolute;
      transform: scaleY(0);
      transform-origin: top;
      box-sizing: border-box;
      height: 190px;
      right: -100px;
      width: 540px;
      z-index: 2;
      opacity: 0;
      transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;


      @media all and (max-width: 767px) {
        width: calc(100vw - 32px);
        right: 0;
        padding: 44px 0 20px;
      }

      ol {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-end;
      }

      li {
        margin-bottom: 6px;
        width: 180px;

        a {
          white-space: nowrap;
          color: $black_text_color;
          font-size: 16px;
          text-decoration: none;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          display: flex;
          align-items: center;
          position: relative;


          .inner_arrow {
            transform: translateX(0);
            opacity: 0;
            transition: all 0.3s ease-in-out;
            @media all and (max-width: 768px) {
              display: none;
            }

            path {
              stroke: $black_text_color;
              transition: all 0.3s ease-in-out;

            }

          }

          &:hover {
            text-decoration: underline;

            .inner_arrow {
              transform: translateX(30px);
              opacity: 1;
              transition: all 0.3s ease-in-out;

            }
          }
        }
      }

    }
  }


}
