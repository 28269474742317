.ready_to_start {
  margin-bottom: 130px;
  @media all and (max-width: 1024px) {
    margin-bottom: 70px;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_title {
    color: $black_text_color;
    text-align: center;
    font-family: $Kameron-400;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
    margin: 0 0 40px;
    @media all and (max-width: 1024px) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  &_cta {
    text-decoration: none;
  }
}