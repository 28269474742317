.submit_section {
  &_wrapper {
    display: flex;
    max-width: 1080px;
    margin: 0 auto;
    align-items: flex-start;
    @media all and (max-width: 576px) {
      flex-direction: column;
    }
  }

  &_help {
    border-radius: 20px;
    border: 1px solid rgba(25, 25, 25, 0.12);
    background: $white_text_color;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
    padding: 26px 22px 54px;
    box-sizing: border-box;
    margin-right: 150px;

    @media all and (max-width: 1024px) {
      margin-right: 90px;
      max-width: 216px;
    }
    @media all and (max-width: 767px) {
      margin-right: 30px;
    }
    @media all and (max-width: 576px) {
      margin: 0 auto 70px;
    }

    img {
      display: block;
      width: 100%;
      margin-bottom: 30px;
    }

    h2 {
      color: $black_text_color;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 26px;
      max-width: 232px;
      @media all and (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    a {
      display: block;
      border-radius: 500px;
      background: $black_text_color;
      color: $white_text_color;
      text-align: center;
      font-family: $Arial-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 16px;
      line-height: normal;
      padding: 8px 34px 10px;
      box-sizing: border-box;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      border: 1px solid transparent;
      @media all and (max-width: 1024px) {
        font-size: 14px;
      }

      &:hover {
        transition: all 0.3s ease-in-out;
        background: $white_text_color;
        color: $black_text_color;
        border-color: $black_text_color;
      }
    }
  }

  &_form {

    flex: 1;
    @media all and (max-width: 576px) {
      flex: none;
      width: 100%;
    }

    .form_input {
      display: flex;
      flex-direction: column;
      margin: 0 0 12px;

      &.last {
        margin: 0 0 34px;
      }

      &_label {
        color: $black_text_color;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        @media all and (max-width: 1024px) {
          font-size: 14px;
        }
      }

      textarea {
        &::placeholder {
          font-family: $Arial-400;
        }
      }

      &_input {
        color: $black_text_color_50;
        border-radius: 10px;
        border: 1px solid rgba(25, 25, 25, 0.24);
        background: rgba(255, 255, 255, 0.50);
        padding: 10px 16px 12px;
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;

        &.contact, &.category {
          max-width: 380px;
          @media all and (max-width: 1024px) {
            max-width: none;
          }
        }

        &.file {
          visibility: hidden;
          height: 0;
          width: 0;
        }
      }

    }

    .input_file_wrapper {
      display: flex;
      @media all and (max-width: 576px) {
        flex-direction: column;
      }

      .file_label {
        cursor: pointer;
        flex: 1;
        display: flex;
        align-items: center;
        color: $blue_color;
        text-decoration: underline;
        border-radius: 10px;
        border: 1px solid rgba(25, 25, 25, 0.24);
        background: rgba(255, 255, 255, 0.50);
        padding: 10px 16px 12px;
        box-sizing: border-box;
        margin-right: 40px;
        white-space: nowrap;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        @media all and (max-width: 576px) {
          margin: 0 0 12px;
        }

        svg {
          display: block;
          margin-right: 10px;
        }
      }

      .file_value {
        display: flex;
        align-items: center;
        flex: 1;

        &_check {
          display: block;
          margin-right: 10px;
        }

        &_value {
          padding: 0 6px;
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-700;
          font-size: 14px;
          line-height: 32px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &_delete {
          cursor: pointer;
          display: block;
          margin-left: auto;
        }
      }
    }

    .main_button {
      display: flex;
      width: 100%;
    }

  }
}