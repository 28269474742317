@import "variables";

.preview_link {
  margin-left: 12px;
  transition: all 0.3s ease-in-out;
  border-radius: 500px;
  border: none;
  color: #191919;
  padding: 8px 16px;
  font-family: $Arial-700;
  text-decoration: none;
  box-shadow: 0 0 15px rgba(80, 241, 0, 1), 0 0 25px rgba(80, 241, 0, 1);
  font-size: 12px;
  background: linear-gradient(99deg, #3CEFA3 6.77%, #F6FC5A 85.14%);
  position: relative;
  @media all and (max-width: 1024px) {
    margin-left: 0;
  }

  &:before, &:after {
    border-radius: 500px;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(80, 241, 0, 1);
    z-index: -1;
    opacity: 0.7;
  }

  &:before {
    animation: pulse 2s ease-out infinite;
  }

  &:after {
    animation: pulse 2s 1s ease-out infinite;
  }
  @keyframes pulse {
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }
  @keyframes pulse-mobile {
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }
}

.lang_hidden_item_flag {
  width: 20px;
  height: 14px;
  margin-right: 10px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}
.products, .trade, .faqs, .service, .contact_us, .education_center {
  .header {
    background: #fff;
    z-index: 10;

    .nav_burger {
      path {
        fill: #191919;
      }
    }

    &_wrapper {
      .nav_block {
        .main_nav_item {
          &_text {
            color: $black_text_color;
          }
        }
      }

      .nav_left, .nav_right {
        &_list {
          .full_width {
            background: #fff;
            backdrop-filter: none;
          }

          &_inner {
            &_link {
              color: $black_text_color;

              .inner_arrow {
                path {
                  stroke: $black_text_color;
                }
              }
            }
          }
        }
      }

      .nav_logo {
        position: relative;
        display: block;
        padding-left: -30px;

        &:before {
          position: absolute;
          content: 'Beta';
          color: #000;
          font-family: Arial-700, sans-serif;
          font-size: 12px;
          top: 0;
          left: -31px;
          @media all and (max-width: 576px) {
            font-size: 10px;
            left: -28px;
          }
        }
        svg {
          path {
            fill: $black_text_color;
          }
        }
      }

      .nav_right {
        .lang {
          &_list {
            .full_width {
              background: #fff;
              backdrop-filter: none;
            }

            .lang_hidden_wrapper {

              li {
                a {
                  color: $black_text_color;
                  transition: all 0.3s ease-in-out;

                  .inner_arrow {
                    path {
                      stroke: $black_text_color;
                      transition: all 0.3s ease-in-out;
                    }
                  }
                }
              }
            }

            .lang_current {
              .curr_img {
                path {
                  fill: $black_text_color;
                }
              }

              .lang_title {
                color: $black_text_color;
              }
            }
          }
        }

        .nav_buy_button {
          button {
            border: 1px solid $black_text_color;
            color: $black_text_color;

            &:hover {
              background: $black_text_color;
              transition: all 0.3s ease-in-out;
              border-color: $black_text_color;
              color: $white_text_color;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  padding: 24px 0;
  top: 0;
  z-index: 3;
  transition: all 0.3s ease-in-out;
  @media all and (max-width: 1024px) {
    padding: 12px 0;
  }
  @media all and (max-width: 768px) {
    padding: 18px 0;
  }

  &.scrolled, &.blur {
    transition: all 0.3s ease-in-out;
    background: #fff;

    .nav_burger {
      path {
        fill: #191919;
      }
    }

    .header_wrapper {
      .main_nav_item {
        &_text {
          color: $black_text_color;
          transition: all 0.3s ease-in-out;
        }
      }

      .nav_logo {
        position: relative;
        display: block;
        padding-left: -30px;

        &:before {
          position: absolute;
          content: 'Beta';
          color: #000;
          font-family: Arial-700, sans-serif;
          font-size: 12px;
          top: 0;
          left: -31px;
          @media all and (max-width: 576px) {
            font-size: 10px;
            left: -28px;
          }
        }
        svg {
          path {
            transition: all 0.3s ease-in-out;
            fill: $black_text_color;
          }
        }
      }

      .nav_left, .nav_right {
        &_list {
          .full_width {
            background: #fff;
            backdrop-filter: none;
          }

          &_inner {
            &_link {
              color: $black_text_color;
              transition: all 0.3s ease-in-out;

              .inner_arrow {
                path {
                  stroke: $black_text_color;
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }
        }
      }

      .nav_right {
        .lang {
          &_list {
            .full_width {
              background: #fff;
              backdrop-filter: none;
            }

            li {


              a {

                color: $black_text_color;
                transition: all 0.3s ease-in-out;

                .inner_arrow {
                  path {
                    stroke: $black_text_color;
                    transition: all 0.3s ease-in-out;
                  }
                }
              }
            }

            .lang_current {

              .curr_img {
                margin-right: 12px;

                path {
                  transition: all 0.3s ease-in-out;
                  fill: $black_text_color;
                }
              }

              .lang_title {
                transition: all 0.3s ease-in-out;
                color: $black_text_color;
              }
            }
          }
        }

        .nav_buy_button {

          button {
            color: $black_text_color;
            border-color: $black_text_color;
            transition: all 0.3s ease-in-out;

            &:hover {
              background: $black_text_color;
              transition: all 0.3s ease-in-out;
              border-color: $black_text_color;
              color: $white_text_color;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav_block {
      margin: 0 -20px;
      @media all and (max-width: 1200px) {
        margin: 0 -12px;
      }

      .main_nav_item {
        padding: 0 20px;
        cursor: pointer;
        position: relative;
        @media all and (max-width: 1200px) {
          padding: 0 12px;
        }

        &:hover {
          .main_nav_item_text:after {
            transition: opacity 0.2s ease-in-out;
            opacity: 1;
          }

          .nav_left_list_inner {
            transform: scaleY(1);
            transition: all 0.3s ease-in-out;
          }

          .full_width {
            backdrop-filter: blur(25px);
            transform: scaleY(1);
            transition: all 0.3s ease-in-out;
          }
        }

        &_text {
          text-decoration: none;
          color: $white_text_color;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 14px;
          line-height: normal;
          display: block;
          position: relative;
          height: 100%;

          &:after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: -20px;
            height: 3px;
            opacity: 0;
            left: 0;
            background: $blue_color;
            transition: opacity 0.2s ease-in-out;
          }
        }
      }
    }

    .nav_burger {
      display: none;
      cursor: pointer;

      @media all and (max-width: 1024px) {
        display: block;
      }
    }

    .mobile_menu {
      width: 323px;
      box-sizing: border-box;
      display: none;
      position: absolute;
      left: -100%;
      visibility: hidden;
      top: 0;
      height: 102vh;
      background: rgba(236, 239, 245, 1);
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.36);
      padding: 24px 24px 24px;
      overflow: auto;
      transition: all ease-in-out 0.3s;

      .preview_link {

        z-index: 2;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        border-radius: 500px;
        border: none;
        color: #191919;
        padding: 16px 32px;
        font-family: $Arial-700;
        box-shadow: 0 0 15px rgba(80, 241, 0, 1), 0 0 25px rgba(80, 241, 0, 1);
        font-size: 14px;
        background: linear-gradient(99deg, #3CEFA3 6.77%, #F6FC5A 85.14%);
        position: relative;
        margin-bottom: 40px;

        &:before, &:after {
          border-radius: 500px;
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 100%;
          background: rgba(80, 241, 0, 1);
          z-index: -1;
          opacity: 0.7;
        }

        &:before {
          animation: pulse-mobile 3s ease-out infinite;
        }

        &:after {
          animation: pulse-mobile 3s 2s ease-out infinite;
        }
        @keyframes pulse-mobile {
          100% {
            transform: scale(2.5);
            opacity: 0;
          }
        }
      }

      &.visible {
        z-index: 2;
        left: 0;
        visibility: visible;
        transition: all ease-in-out 0.3s;
      }

      @media all and (max-width: 1024px) {
        display: block;
      }

      .accordion_container {
        .accordion_item_title {
          font-size: 28px;
          line-height: 56px;
        }

        .accordion_data {
          .accordion_item_wrapper {
            margin-bottom: 10px;

            .accordion_item_icon {
              font-size: 28px;
              display: flex;
              width: auto;
              height: auto;
              min-width: 21px;

              &_symbol {
                transform: rotate(0);
                transition: all 0.3s ease-in-out;
              }

              &.open {
                .accordion_item_icon_symbol {
                  transform: rotate(225deg);
                  color: $blue_color;
                  transition: all 0.3s ease-in-out;
                }
              }

              &_locale {
                color: $black_text_color_50;
                text-transform: uppercase;
                font-size: 14px;
                font-family: $Arial-400;
                letter-spacing: 0.6px;
                font-weight: 400;
                margin-right: 15px;
                display: block;
              }
            }
          }
        }
      }


      .accordion_item_body {
        a {
          text-decoration: none;
        }

        .item_link, .lang_hidden_item {
          display: flex;
          align-items: center;
          color: $black_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 16px;
          line-height: 36px;
          text-decoration: none;
        }
      }

      &_wrapper {
        position: relative;
        padding: 108px 0 48px;
        box-sizing: border-box;
      }

      &_close {
        position: absolute;
        top: 3px;
        left: -3px;
        cursor: pointer;

      }

      &_buy_button {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        color: $black_text_color;
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Arial-400;
        letter-spacing: 0.6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 36px;
        text-decoration: none;
      }

      &_start_button {
        margin-top: 50px;
        display: flex;
        text-decoration: none;

        button {
          width: 100%;
        }
      }

    }

    .nav_left {
      @media all and (max-width: 1024px) {
        display: none;
      }
    }

    .nav_left, .nav_right {


      &_list {
        display: flex;
        height: 100%;

        .full_width {
          backdrop-filter: none;
          background: transparent;
          transform-origin: top;
          transform: scaleY(0);
          position: absolute;
          left: -1000px;
          right: 0;
          top: 50px;
          width: 200vw;
          height: 210px;
          transition: all 0.3s ease-in-out;
        }

        &_inner {
          transform-origin: top;
          transform: scaleY(0);
          position: absolute;
          left: 20px;
          padding: 60px 0 20px;
          transition: all 0.3s ease-in-out;
          @media all and (max-width: 1200px) {
            left: 12px;
          }
          @media all and (max-width: 1024px) {
            left: 20px;
          }

          &_link {
            white-space: nowrap;
            color: $white_text_color;
            font-size: 16px;
            text-decoration: none;
            font-family: $Arial-400;
            letter-spacing: 0.6px;
            font-weight: 400;
            margin-bottom: 6px;
            display: flex;
            align-items: center;

            .inner_arrow {
              transform: translateX(0);
              opacity: 0;
              transition: all 0.3s ease-in-out;
            }

            &:hover {
              text-decoration: underline;

              .inner_arrow {
                transform: translateX(30px);
                opacity: 1;
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }
      }
    }

    .nav_right {
      &_list {
        .full_width {
          width: 250vw;
          left: -1500px;
          @media all and (max-width: 1024px) {
            top: 38px;
          }
        }
      }

      .nav_block {
        .main_nav_item_text:after {
          bottom: -13px;
        }
      }

      .nav_left_list_inner {
        left: initial;
        right: -6px;

        li {
          a {
            justify-content: flex-end;
          }
        }
      }
    }

    .nav_logo {
      margin: 0 16px;
      position: relative;
      display: block;
      padding-left: -30px;

      &:before {
        position: absolute;
        content: 'Beta';
        color: #fff;
        font-family: Arial-700, sans-serif;
        font-size: 12px;
        top: 0;
        left: -31px;
        @media all and (max-width: 576px) {
          font-size: 10px;
          left: -28px;
        }
      }
      @media all and (max-width: 576px) {
        width: 130px;
        svg {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      svg {
        path {
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .nav_right {
      margin: 0 -20px;

      &_list {
        display: flex;
        align-items: center;

        .phone_show {
          display: none;
          @media all and (max-width: 767px) {
            display: block;
          }
        }

        .phone_hidden {
          @media all and (max-width: 767px) {
            display: none;
          }
        }

        .mobile_hidden {
          display: block;
          @media all and (max-width: 1024px) {
            display: none;
          }
        }
      }

      .lang {
        &_list {
          position: relative;

          .full_width {
            backdrop-filter: none;
            background: transparent;
            transform-origin: top;
            transform: scaleY(0);
            position: absolute;
            right: 0;
            top: 50px;
            width: 250vw;
            left: -1500px;
            height: 170px;
            transition: all 0.3s ease-in-out;
            @media all and (max-width: 1024px) {
              top: 38px;
            }
          }

          &:hover {
            .full_width {
              backdrop-filter: blur(25px);
              transform: scaleY(1);
              transition: all 0.3s ease-in-out;
            }

            .lang_hidden_wrapper {
              transform: scaleY(1);
              transition: all 0.3s;
            }

            .lang_current:after {
              transition: opacity 0.2s ease-in-out;
              opacity: 1;

            }
          }

          .href_lang {
            span {
              display: flex;
              align-items: center;
            }
          }

          .lang_current {
            display: flex;
            align-items: center;
            padding: 0 20px;
            cursor: pointer;

            &:after {
              content: '';
              position: absolute;
              width: calc(100% - 40px);
              bottom: -16px;
              height: 3px;
              opacity: 0;
              left: 50%;
              transform: translateX(-50%);
              background: $blue_color;
              transition: opacity 0.2s ease-in-out;
              @media all and (max-width: 1024px) {
                bottom: -8px;
              }
            }

            .curr_img {
              margin-right: 12px;
              position: relative;

              path {
                transition: all 0.3s ease-in-out;
              }
            }

            .lang_title {
              transition: all 0.3s ease-in-out;
              text-decoration: none;
              color: $white_text_color;
              font-family: $Arial-400;
              letter-spacing: 0.6px;
              font-weight: 400;
              font-size: 14px;
              line-height: normal;
              display: block;
              text-transform: capitalize;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .lang_hidden_wrapper {
            display: flex;
            top: 100%;
            padding: 60px 0 20px;
            position: absolute;
            transform: scaleY(0);
            transform-origin: top;
            transition: all 0.3s;
            box-sizing: border-box;
            height: 190px;
            right: -100px;
            width: 540px;

            ol {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              align-items: flex-end;
            }

            li {
              margin-bottom: 6px;
              width: 180px;

              a {
                white-space: nowrap;
                color: rgb(255, 255, 255);
                font-size: 16px;
                text-decoration: none;
                font-family: $Arial-400;
                letter-spacing: 0.6px;
                font-weight: 400;
                display: flex;
                align-items: center;
                position: relative;


                .inner_arrow {
                  transform: translateX(0);
                  opacity: 0;
                  transition: all 0.3s ease-in-out;
                }

                &:hover {
                  text-decoration: underline;

                  .inner_arrow {
                    transform: translateX(30px);
                    opacity: 1;
                    transition: all 0.3s ease-in-out;
                  }
                }
              }
            }

          }
        }
      }

      .nav_buy_button {
        padding: 0 20px;

        button {
          transition: all 0.3s ease-in-out;
          border-radius: 500px;
          border: 1px solid #FFF;
          color: $white_text_color;
          padding: 8px 18px;
          background: transparent;

          &:hover {
            transition: all 0.3s ease-in-out;
            border-color: #FFF;
            background: #FFF;
            color: $black_text_color;
            cursor: pointer;
          }
        }
      }
    }
  }
}