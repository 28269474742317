.main_features {
  padding: 110px 0 0px;
  position: relative;
  @media all and (max-width: 1024px) {
    padding: 90px 0 90px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(51, 102, 255, 0.00) 0%, #36F 100%);
    opacity: 0.08;
    top: 0;
    left: 0;
    transform: rotateX(180deg);
    z-index: -1;
  }

  &_title {
    color: $black_text_color;
    text-align: center;
    font-family: $Kameron-400;
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
    margin: 0 auto 20px;
    max-width: 510px;
    @media all and (max-width: 1024px) {
      font-size: 24px;
      line-height: 28px;
      margin: 0 auto 28px;
    }
  }

  &_description {
    color: $black_text_color;
    max-width: 836px;
    text-align: center;
    font-family: $Arial-400;
    letter-spacing: 0.6px;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    margin: 0 auto 96px;
    @media all and (max-width: 1024px) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 auto 50px;
      max-width: 472px;
    }
  }

  .slider_buttons_wrapper {
    margin: 0 0 30px;
    padding: 0 110px;
    box-sizing: border-box;
    @media all and (max-width: 1024px) {
      padding: 0;
    }

    .slider_buttons {
      margin: 0 0 30px;
      display: flex;
      align-items: center;
      padding: 0;
      overflow-x: scroll;
      justify-content: space-between;
      -webkit-overflow-scrolling: touch;
      @media all and (max-width: 767px) {
        flex-wrap: wrap;
        overflow: initial;
        justify-content: flex-start;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {

        border-radius: 20px;
        border: 2px solid $black_text_color_50;
      }


      &_button {
        margin-right: 20px;
        box-sizing: border-box;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          button {
            color: $black_text_color;
            text-decoration: underline;
          }
        }

        button {
          cursor: pointer;
          border: none;
          background: none;
          color: $black_text_color_50;
          font-family: $Arial-700;
          font-size: 18px;
          line-height: 28px; /* 210% */
          letter-spacing: 0.2px;
          white-space: nowrap;
          padding: 0;
          @media all and (max-width: 1024px) {
            font-size: 12px;
            line-height: 36px;
          }
        }
      }
    }
  }

  .features_swiper_wrapper {
    padding: 0 110px;
    box-sizing: border-box;
    position: relative;
    @media all and (max-width: 1024px) {
      padding: 8px;
    }

    .swiper {
      height: 100%;
      overflow: visible !important;
    }

    .swiper-button-next, .swiper-button-prev {
      position: absolute;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      @media all and (max-width: 1024px) {
        height: 36px;
        width: 36px;
        z-index: 10;
      }
    }

    .swiper-button-prev {
      &.swiper-button-disabled {
        cursor: default !important;
      }

      &:not(.swiper-button-disabled):hover {
        &:after {
          transition: all 0.3s ease-in-out;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMSIgd2lkdGg9IjQ2IiBoZWlnaHQ9IjQ2IiByeD0iMjMiIGZpbGw9IiMxOTE5MTkiIHN0cm9rZT0iIzE5MTkxOSIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0yMyAzMkwxNSAyNEwyMyAxNiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0xNC45OTk5IDIzLjk5ODhMMzMuMjg1NiAyMy45OTg4IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
          @media all and (max-width: 1024px) {
            height: 36px;
            width: 36px;
            z-index: 10;
          }
        }
      }

      top: 34%;
      left: -110px;
      cursor: pointer;
      @media all and (max-width: 1024px) {
        top: initial;
        left: calc(50% - 46px);
        bottom: -46px;
        transform: translateX(-50%);
      }
      @media all and (max-width: 576px) {
        left: 0;
        transform: none;
      }

      &:after {
        transition: all 0.3s ease-in-out;
        display: block;
        width: 48px;
        height: 48px;
        content: '';
        background-size: cover;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMSIgd2lkdGg9IjQ2IiBoZWlnaHQ9IjQ2IiByeD0iMjMiIHN0cm9rZT0iIzE5MTkxOSIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0yMi45OTk5IDMyTDE0Ljk5OTkgMjRMMjIuOTk5OSAxNiIgc3Ryb2tlPSIjMTkxOTE5IiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTE0Ljk5OTkgMjMuOTk4OEwzMy4yODU2IDIzLjk5ODgiIHN0cm9rZT0iIzE5MTkxOSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=");
        @media all and (max-width: 1024px) {
          height: 36px;
          width: 36px;
          z-index: 10;
        }
      }
    }

    .swiper-button-next {
      top: 34%;
      right: -110px;
      cursor: pointer;
      @media all and (max-width: 1024px) {
        bottom: -46px;
        top: initial;
        right: calc(50% - 46px);
        transform: translateX(-50%);
      }
      @media all and (max-width: 576px) {
        right: initial;
        left: 46px;
        transform: none;
      }

      &.swiper-button-disabled {
        cursor: default !important;
      }

      &:not(.swiper-button-disabled):hover {
        &:after {
          transition: all 0.3s ease-in-out;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iNDciIHk9IjQ3IiB3aWR0aD0iNDYiIGhlaWdodD0iNDYiIHJ4PSIyMyIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwIDQ3IDQ3KSIgZmlsbD0iIzE5MTkxOSIgc3Ryb2tlPSIjMTkxOTE5IiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTI1IDE2TDMzIDI0TDI1IDMyIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTMzLjAwMDEgMjQuMDAxMkwxNC43MTQ0IDI0LjAwMTIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");

        }
      }

      &:after {
        transition: all 0.3s ease-in-out;
        display: block;
        width: 48px;
        height: 48px;
        content: '';
        background-size: cover;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iNDciIHk9IjQ3IiB3aWR0aD0iNDYiIGhlaWdodD0iNDYiIHJ4PSIyMyIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwIDQ3IDQ3KSIgc3Ryb2tlPSIjMTkxOTE5IiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTI1LjAwMDEgMTZMMzMuMDAwMSAyNEwyNS4wMDAxIDMyIiBzdHJva2U9IiMxOTE5MTkiIHN0cm9rZS13aWR0aD0iMiIvPgo8cGF0aCBkPSJNMzMuMDAwMSAyNC4wMDEyTDE0LjcxNDQgMjQuMDAxMiIgc3Ryb2tlPSIjMTkxOTE5IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==");
        @media all and (max-width: 1024px) {
          height: 36px;
          width: 36px;
          z-index: 10;
        }
      }
    }

    .features_slider_slide {
      .slide_media {
        border-radius: 32px;
        overflow: hidden;
        position: relative;
        margin-bottom: 40px;
        max-height: 512px;
        @media all and (max-width: 576px) {
          border-radius: 8px;
        }

        &_img {
          width: 100%;
          display: block;
          height: auto;
          object-fit: cover;
        }

        &_chat {
          position: absolute;
          display: block;
          width: 50%;
          height: auto;
          left: 0;
          top: 0;

          &.multi_asset {
            width: 42%;
          }
        }
      }

      .slide_description {
        display: flex;
        @media all and (max-width: 576px) {
          flex-direction: column;
        }

        &_title {
          width: 50%;
          padding-right: 40px;
          box-sizing: border-box;
          @media all and (max-width: 576px) {
            width: 100%;
            padding: 0;
          }

          &_title {
            color: $black_text_color;
            font-family: $Arial-400;
            letter-spacing: 0.6px;
            font-weight: 400;
            font-size: 36px;
            line-height: 42px;
            margin: 0;
            max-width: 440px;
            @media all and (max-width: 1024px) {
              font-size: 20px;
              line-height: 28px;
              max-width: 250px;
            }
            @media all and (max-width: 576px) {
              margin-bottom: 30px;
            }
          }
        }

        &_text {
          width: 50%;
          @media all and (max-width: 576px) {
            width: 100%;
          }

          &_text {
            color: $black_text_color;
            font-family: $Arial-400;
            letter-spacing: 0.6px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 0;
            @media all and (max-width: 1024px) {
              font-size: 14px;
              line-height: 20px;
            }

            &:last-child {
              margin: 0;
            }
          }

          &_cta {
            color: $black_text_color;
            padding: 0 4px;
            text-decoration: underline;
          }
        }
      }
    }
  }

}